import {SortBy, SortByDirection, User, EditUserData} from "../utils/types";
import {UserManagementActions} from "./actions/userManagementActions";
import {usersColumnsDefs} from "../utils/constants";
import {sortItems} from "../utils/utils";


export interface UserManagementState {
    originalUsers: User[];
    filteredAndSortedUsers: User[];
    pagedUsers: User[];
    nameFilter: string;
    editedUser: (EditUserData & {id: number}) | null;
    sortBy: SortBy;
    pageSize: number;
    page: number;
}


const usersInitialState: UserManagementState = {
    originalUsers: [],
    filteredAndSortedUsers: [],
    pagedUsers: [],
    nameFilter: "",
    editedUser: null,
    sortBy: {
        index: 0,
        key: usersColumnsDefs[0].sortKey,
        direction: SortByDirection.asc
    },
    pageSize: 10,
    page: 1
};

export const getUsersInitialState = () => usersInitialState;

function getPagedUsers(users: User[], page: number, pageSize: number) {

    const paginationStartIndex = (page - 1) * pageSize;
    const paginationEndIndex = page * pageSize;

    return users.slice(paginationStartIndex, paginationEndIndex);
}

function filterUsers(users: User[], filter: string){
    if (filter){
        const filterLowerCase = filter.toLowerCase();
        return users.filter(user => user.name.toLowerCase().indexOf(filterLowerCase) > -1);
    }
    return users;
}


export function userManagementReducer(state: UserManagementState, action: UserManagementActions): UserManagementState {

    switch (action.type) {
        case "SET_USERS": {
            const filteredUsers = filterUsers(action.users, state.nameFilter);
            const filteredAndSortedUsers = sortItems(filteredUsers, state.sortBy);
            const pagedUsers = getPagedUsers(filteredAndSortedUsers, state.page, state.pageSize);

            return {
                ...state,
                originalUsers: action.users,
                filteredAndSortedUsers,
                pagedUsers
            };
        }

        case "SET_PAGE": {
            const pagedUsers = getPagedUsers(state.filteredAndSortedUsers, action.page, state.pageSize);

            return {
                ...state,
                page: action.page,
                pagedUsers
            };
        }

        case "SET_PAGE_SIZE": {
            const pagedUsers = getPagedUsers(state.filteredAndSortedUsers, state.page, action.pageSize);

            return {
                ...state,
                pageSize: action.pageSize,
                pagedUsers
            };
        }

        case "SET_SORT_BY": {
            const filteredUsers = filterUsers(state.originalUsers, state.nameFilter);
            const filteredAndSortedUsers = sortItems(filteredUsers, action.sortBy);
            const pagedUsers = getPagedUsers(filteredAndSortedUsers, state.page, state.pageSize);

            return {
                ...state,
                sortBy: action.sortBy,
                filteredAndSortedUsers,
                pagedUsers
            };
        }

        case "SET_NAME_FILTER": {
            const filteredUsers = filterUsers(state.originalUsers, action.nameFilter);
            const filteredAndSortedUsers = sortItems(filteredUsers, state.sortBy);
            const pagedUsers = getPagedUsers(filteredAndSortedUsers, state.page, state.pageSize);

            return {
                ...state,
                nameFilter: action.nameFilter,
                filteredAndSortedUsers,
                pagedUsers
            };
        }

        case "EDIT_USER":
            return {
                ...state,
                editedUser: action.editedUser
            };


        case "CANCEL_EDIT_USER":
            return {
                ...state,
                editedUser: null
            };


        default:
            return state;
    }
}
