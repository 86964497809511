import {History} from "history";

import {callSecuredApiEndpoint, ApiJsonResponse} from "./apiCalls";
import {SchemeProfileRaw, Project} from "../utils/types";
import {API_BASE_URL} from "../utils/constants";


export type SchemeProfileData = {
    id: number;
    clientReferenceNumber: string | null;
    scheme: {
        id: number;
        name: string | null;
    };
    originalProfile: SchemeProfileRaw | null;
    updatedProfile: SchemeProfileRaw | null;
    urn: string | null;
    project: Partial<Project> | null;
}


export interface DetailRequestJson extends ApiJsonResponse {
    uuid: string;
    confirmedProfile: SchemeProfileRaw | null;
    schemeProfiles: SchemeProfileData[];
    securityCode: string | null;
    nationalInsuranceNumber: string | null;
    id: string | null;
    suspended: boolean | null;

}

/**
 * Definition of fetch call for members endpoint
 */
const requestDetail = (id: string) => (authorization: string) =>
    fetch(
        `${API_BASE_URL}member-schemes/${id}`,

        {
            headers: new window.Headers({
                Authorization: authorization
            }),
            method: "GET"
        }
    ).then(response => response.json()) as Promise<DetailRequestJson>;


export const getDetail = (id: string, history: History) => callSecuredApiEndpoint(requestDetail(id), history);
