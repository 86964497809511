import React, {useState, forwardRef, useImperativeHandle, useRef} from "react";
import {AlertGroup, Alert, AlertActionCloseButton, AlertVariant} from "@patternfly/react-core";

export type ToastAlert = {
    id: number;
    type: "success" | "danger";
    title: string;
    message: string;
}

export type AddToastCall = (toast: Pick<ToastAlert, "title" | "message">) => void;

export interface ToastGroupRef {
    addSuccessToast: AddToastCall;
    addErrorToast: AddToastCall;

}

export interface ToastGroupProps {
    children?: React.ReactNode;
}

const ToastGroup: React.FC<ToastGroupProps> = ({children}, ref) => {
    const [toasts, setToast] = useState<ToastAlert[]>([]);
    const toastsRef = useRef(toasts);

    const updateToasts = (updatedToasts: ToastAlert[]) => {
        // update ref with toasts
        setToast(updatedToasts);
        toastsRef.current = updatedToasts;
    };

    // in timeout old value is used from the time it was scheduled
    // we must use ref to gather recent value
    // https://github.com/facebook/react/issues/14010
    const removeToast = (id: number) => {
        const filteredToasts = toastsRef.current.filter(toast => toast.id !== id);

        // keep ref consistent!
        updateToasts(filteredToasts);
    };

    const addToast = (toast: Omit<ToastAlert, "id">) => {
        const id = Date.now();
        const updatedToasts = [
            ...toasts,
            {
                id,
                ...toast
            }
        ];
        updateToasts(updatedToasts);

        // clear toast automatically
        window.setTimeout(removeToast, 5000, id);
    };

    const addSuccessToast = (toast: Pick<ToastAlert, "title" | "message">) => addToast({type: "success", ...toast});
    const addErrorToast = (toast: Pick<ToastAlert, "title" | "message">) => addToast({type: "danger", ...toast});

    useImperativeHandle(ref, () => ({
        addSuccessToast,
        addErrorToast
    }));


    return (
        <AlertGroup isToast>
            {
                toasts.map(toast => (
                    <Alert
                        isLiveRegion
                        isInline
                        variant={AlertVariant[toast.type]}
                        title={toast.title}
                        action={
                            <AlertActionCloseButton
                                onClose={() => removeToast(toast.id)}
                            />
                        }
                        key={toast.id}
                    >
                        <p>{toast.message}</p>
                    </Alert>
                ))
            }
            {children}
        </AlertGroup>
    );
};

export default forwardRef(ToastGroup);
