import {API_BASE_URL, LoginResultEnum, UserRolesEnum} from "../utils/constants";
import {ApiJsonResponse} from "./apiCalls";

const SESSION_STORAGE_ID = "tp--user-auth";

type TokenMetadata = {
    iss: string; // issuer domain
    aud: string;
    sub: string;
    exp: number; // epxiration
    rol: UserRolesEnum[]; // user role data
    nam: string; // user name
}

type SessionStorageData = {
    token: string | null;
    tokenExpiration: number;
    refreshToken: string | null;
}

export interface LoginJsonResponse extends ApiJsonResponse {
    access_token: string;
    token_type: "bearer";
    expires_in: number;
    refresh_token: string;
}


class AuthTokenStore {

    private metadata: TokenMetadata | null = null;

    private token: string | null = null;

    private tokenExpiration = 0;

    private refreshToken: string | null = null;

    private get tokenIsExpired() {
        return this.tokenExpiration < Date.now();
    }

    private onGoingRefresh: Promise<any> | null = null;

    constructor() {
        try {
            // load session from session storage
            const data: string | null = window.sessionStorage.getItem(SESSION_STORAGE_ID);

            if (data) {
                const decoded = window.atob(data);
                const parsedData: SessionStorageData = JSON.parse(decoded);

                this.token = parsedData.token || null;
                this.tokenExpiration = parsedData.tokenExpiration || 0;
                this.refreshToken = parsedData.refreshToken || null;
                this.metadata = this.extractMetadata();
            }
        } catch (e) {
            console.error("Something wrong with session storage", e);
        }
    }

    private extractMetadata() {
        // skip parsing when no token is provided
        if (this.token === null) {
            return null;
        }

        // sections - 0. token data 1. metadata 2. hash
        const metadataSection = this.token.split(".")[1];
        let metadata = null;

        try {
            metadata = JSON.parse(window.atob(metadataSection));
        } catch (e) {
            console.error("Error handling token metadata", e);
        }

        return metadata;
    }


    /**
     * Update session storage to keep it synced
     */
    private updateSessionStorageData() {
        const data: SessionStorageData = {
            token: this.token,
            tokenExpiration: this.tokenExpiration,
            refreshToken: this.refreshToken
        };
        const json = JSON.stringify(data);
        const base64 = window.btoa(json);

        window.sessionStorage.setItem(SESSION_STORAGE_ID, base64);
    }

    /**
     * Update tokens in store
     */
    private setTokens(token: string, tokenLifetime: number, refreshToken: string) {
        this.token = token;
        this.tokenExpiration = Date.now() + tokenLifetime;
        this.refreshToken = refreshToken;
        this.metadata = this.extractMetadata();

        this.updateSessionStorageData();
    }

    /**
     * Use refresh token to get recent token
     */
    public updateToken = () => {

        // issue new refresh request only once
        if (this.onGoingRefresh === null) {

            this.onGoingRefresh = fetch(API_BASE_URL + "oauth/token", {
                method: "POST",
                body: JSON.stringify({
                    "refresh_token": this.refreshToken,
                    "grant_type": "refresh_token"
                })
            })
                .then(response => response.json())
                .then((json: LoginJsonResponse) => {
                    // console.log("Refresh", json);

                    if (json.error) {
                        console.warn("Update token error response", json.error_description);
                        this.clearTokens();
                        return true;
                    }

                    this.setTokens(
                        json.access_token,
                        json.expires_in,
                        json.refresh_token
                    );
                    return false;
                })
                .catch(e => {
                    console.error("Update token error", e);

                    this.clearTokens();
                    return false;
                })
                .finally(() => {
                    this.onGoingRefresh = null;
                });
        }

        return this.onGoingRefresh;
    };

    public hasToken = () => {
        return !!this.token;
    };

    /**
     * Returns recent token. If its expired it gets revalidated
     */
    public getToken = (): Promise<string | null> => {

        // we already have active session
        if (this.token) {

            if (this.tokenIsExpired) {
                return this.updateToken().then(() => this.token);
            } else {
                return Promise.resolve(this.token);
            }
        }

        return Promise.resolve<null>(null);
    };

    /**
     * Request token from auth endpoint using username and password
     */
    public requestTokens = (username: string, password: string) => {
        return fetch(API_BASE_URL + "oauth/token", {
            method: "POST",
            body: JSON.stringify({
                username,
                password,
                "grant_type": "password"
            })
        })
            .then(response => response.json())
            .then((json: LoginJsonResponse) => {
                //  console.log("Log in", json);

                if (json.error) {
                    if (json.error_description === "Bad credentials") {
                        return LoginResultEnum.INVALID;
                    }
                    return LoginResultEnum.ERROR;

                }
                this.setTokens(json.access_token, json.expires_in, json.refresh_token);

                return LoginResultEnum.SUCCESS;
            })
            .catch(e => {
                console.error("Log in error", e);
                return LoginResultEnum.ERROR;
            });
    };


    /**
     * Clear tokens from local instance and session storage
     */
    public clearTokens = () => {
        window.sessionStorage.removeItem(SESSION_STORAGE_ID);

        this.token = null;
        this.tokenExpiration = 0;
        this.refreshToken = null;
        this.metadata = null;
    };

    public getUsername = () => {
        if (this.metadata && this.metadata.nam){
            return this.metadata.nam;
        }
        return "";
    };

    public isAdminRole = () => {
        if (this.metadata && Array.isArray(this.metadata.rol)){
            return this.metadata.rol.includes(UserRolesEnum.ROLE_ADMIN);
        }
        return false;
    };
}

export default new AuthTokenStore();
