import React from "react";
import {Text} from "@patternfly/react-core";
import DetailField from "./DetailHeaderField";
import {MemberSchemeDetail} from "../../../utils/types";

export interface SchemeDetailProps {
    detail: MemberSchemeDetail;
}

const SchemeDetail: React.FC<SchemeDetailProps> = ({detail}) => (
    <section className="tp--detail__profile">
        <div className="tp--detail__profile__block">
            <Text component="h3">Scheme</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="Name" value={detail.schemeName}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Client Reference Number" value={detail.clientReferenceNumber}/>
        </div>
        <div className="tp--detail__profile__block">
            <Text component="h3">Project</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="Project Number" value={detail.project.number}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Project Client Name" value={detail.project.client.name}/>

        </div>
        <div className="tp--clearfix">&nbsp;</div>
    </section>
);

export default SchemeDetail;
