import React from "react";
import {Text} from "@patternfly/react-core";
import DetailField from "./DetailHeaderField";
import {SchemeProfile} from "../../../utils/types";

export interface DetailProfileProps {
    title: string;
    profile: SchemeProfile;
}

const DetailProfile: React.FC<DetailProfileProps> = ({title, profile}) => (
    <section className="tp--detail__profile">
        <Text component="h2">{title}</Text>
        <div className="tp--detail__profile__block">
            <Text component="h3">Personal</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="Title" value={profile.title}/>
            <DetailField classNameBase="tp--detail__profile__block" label="First Name" value={profile.forename}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Middle Name" value={profile.middleName}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Surname" value={profile.surname}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Sex" value={profile.sex}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Marital Status" value={profile.maritalStatus}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Date of Birth" value={profile.dateOfBirth}/>
            <Text component="h3">Address</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="Address Line 1" value={profile.address.line1}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Address Line 2" value={profile.address.line2}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Town/City" value={profile.address.townCity}/>
            <DetailField classNameBase="tp--detail__profile__block" label="County/Province" value={profile.address.countyOrProvince}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Postcode" value={profile.address.postcode}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Country" value={profile.address.country}/>
        </div>
        <div className="tp--detail__profile__block">
            <Text component="h3">Spouse</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="First Name" value={profile.spouseDetail.forename}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Middle Name" value={profile.spouseDetail.middleName}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Surname" value={profile.spouseDetail.surname}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Sex" value={profile.spouseDetail.sex}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Date of Birth" value={profile.spouseDetail.dateOfBirth}/>
            <Text component="h3">Bank Details</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="Account Number" value={profile.bankDetail.accountNumber}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Account Name" value={profile.bankDetail.accountName}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Sort Code" value={profile.bankDetail.sortCode}/>
            <Text component="h3">More Info</Text>
            <DetailField classNameBase="tp--detail__profile__block" label="Email" value={profile.email}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Telephone" value={profile.telephone}/>
            <DetailField classNameBase="tp--detail__profile__block" label="Last Updated" value={profile.lastUpdated}/>
        </div>
        <div className="tp--clearfix">&nbsp;</div>
    </section>
);

export default DetailProfile;
