import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";

import {getMembers} from "../../services/membersSchemeService";
import {UNAUTHORIZED_ERR_NAME} from "../../utils/constants";
import {MemberScheme} from "../../utils/types";
import {normalizeSexValue} from "../../utils/utils";


export function fetchMemberSchemesHook(query = "") {
    const [totalCount, setTotalCount] = useState(0);
    const [result, setResult] = useState<MemberScheme[]>([]);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const history = useHistory();


    useEffect(() => {
        async function fetchMembers() {
            try {
                setLoading(true);
                setDone(false);

                const membersJson = await getMembers(query, history);
                const total = membersJson.totalElements;
                const members = membersJson.content.map(memberScheme => {

                    const internalMemberScheme: MemberScheme = {
                        id: memberScheme.id,
                        urn: memberScheme.urn || "",
                        name: memberScheme.name || "",
                        schemeName: memberScheme.schemeName || "",
                        clientName: memberScheme.clientName || "",
                        memberId: memberScheme.memberId || "",
                        nin: memberScheme.nationalInsuranceNumber || "",
                        securityCode: memberScheme.securityCode || "",
                        gender: normalizeSexValue(memberScheme.sex || ""),
                        verified: memberScheme.verified || false,
                        suspended: memberScheme.suspended || false,
                        blocked: memberScheme.blocked || false,
                        registrationId: memberScheme.registrationId || ""
                    };

                    return internalMemberScheme;
                });

                setResult(members);
                setTotalCount(total);
                setLoading(false);
                setDone(true);

            } catch (error) {
                if (error && error.message !== UNAUTHORIZED_ERR_NAME) {
                    setLoading(false);
                }
            }
        }

        fetchMembers();
    }, [query]);

    return [result, totalCount, loading, done] as [MemberScheme[], number, boolean, boolean];
}
