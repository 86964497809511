import React from "react";
import {EmptyState, EmptyStateBody, EmptyStateIcon, Title} from "@patternfly/react-core";
import {CubesIcon} from "@patternfly/react-icons";

import PageWrapper from "../common/page/PageWrapper";
import {Link} from "react-router-dom";
import {Routes} from "../utils/constants";

const PageNotFoundPage: React.FC = () => {

    return (
        <PageWrapper>
            <EmptyState variant="full">
                <EmptyStateIcon icon={CubesIcon}/>
                <Title headingLevel="h5" size="lg">
                    Page Not Found
                </Title>
                <EmptyStateBody>
                    <p>Requested page does not exists. Sorry.</p>
                    <p className="tp--go-home-link">
                        <Link to={Routes.MemberSchemes}>Return back to home page</Link>
                    </p>
                </EmptyStateBody>
            </EmptyState>
        </PageWrapper>
    );
};

export default PageNotFoundPage;
