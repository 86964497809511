import React from "react";

export interface DetailFieldProps {
    label: string;
    value: string;
    classNameBase?: string;
}

const DetailField: React.FC<DetailFieldProps> = ({label, value, classNameBase = "tp--detail"}) => (
    <div className={`${classNameBase}__member-field`}>
        <p className={`${classNameBase}__member-field__label`}>
            <span>{label}:</span>
        </p>
        <p className={`${classNameBase}__member-field__value`}>{value}</p>
    </div>
);

export default DetailField;
