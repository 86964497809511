import React from "react";
import {
    Title,
    EmptyState,
    EmptyStateIcon,
    EmptyStateBody,
    Alert
} from "@patternfly/react-core";
import {WarningTriangleIcon} from "@patternfly/react-icons";

export interface ErrorStateProps {
    errorText: string;
    title?: string;
}

const ErrorState: React.FC<ErrorStateProps> = ({errorText, title = "Search failure"}) => {
    return (
        <EmptyState>
            <EmptyStateIcon icon={WarningTriangleIcon}/>
            <Title size="lg">
                No results found
            </Title>
            <EmptyStateBody>
                <Alert
                    variant="danger"
                    isInline
                    title={title}
                >
                    {errorText}
                </Alert>
            </EmptyStateBody>
        </EmptyState>
    );
};

export default ErrorState;
