import {SortBy, SortByDirection} from "../utils/types";
import {memberSchemesColumsDefs} from "../utils/constants";
import {MemberSchemesActions} from "./actions/memberSchemesActions";


export interface MemberSchemesState {
    sortBy: SortBy;
    pageSize: number;
    page: number;
    filtersQuery: string;
    filterChangedSinceSearch: boolean;
}


const memberSchemesInitialState: MemberSchemesState = {
    sortBy: {
        index: 1,
        key: memberSchemesColumsDefs[1].sortKey,
        direction: SortByDirection.asc
    },
    pageSize: 10,
    page: 1,
    filtersQuery: "",
    filterChangedSinceSearch: false
};

export const getMemberSchemesInitialState = () => memberSchemesInitialState;


export function memberSchemesStateReducer(state: MemberSchemesState, action: MemberSchemesActions): MemberSchemesState {

    switch (action.type){

        case "SET_PAGE":
            return {
                ...state,
                page: action.page
            };

        case "SET_PAGE_SIZE":
            return {
                ...state,
                pageSize: action.pageSize
            };

        case "SET_SORT_BY":
            return {
                ...state,
                sortBy: action.sortBy
            };

        case "SET_SCHEMES_FILTERS_QUERY":
            return {
                ...state,
                page: memberSchemesInitialState.page,
                filtersQuery: action.query,
                filterChangedSinceSearch: false
            };

        // Simply replace query - used with updating timestamp to reload data
        case "SCHEMES_FILTER_RELOAD":
            return {
                ...state,
                filtersQuery: action.query
            };

        case "SCHEMES_FILTER_CHANGED":
            return {
                ...state,
                filterChangedSinceSearch: true
            };

        case "RESET_SCHEMES_STATE":
            return getMemberSchemesInitialState();

        default:
            return state;
    }
}
