import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, ButtonVariant, Modal, Spinner} from "@patternfly/react-core";

import {getExportCsv} from "../../../services/membersSchemeService";
import {formatDateForExportName} from "../../../utils/utils";

export interface MemberSchemesExportButtonProps {
    query: string;
    disabled: boolean;

}

const MemberSchemesExportButton: React.FC<MemberSchemesExportButtonProps> = ({query, disabled}) => {

    const [confirmShown, setConfirmShown] = useState(false);
    const [exporting, setExporting] = useState(false);
    const history = useHistory();

    const showConfirmModal =  (e: React.MouseEvent) => {
        e.preventDefault();

        setConfirmShown(true);
    };

    const hideConfirmModal = () => setConfirmShown(false);

    const downloadExportedContent = (e: React.MouseEvent) => {
        e.preventDefault();

        hideConfirmModal();

        getExportCsv(query, history)
            .then(blob => {

                const anchor = document.createElement("a");
                const objectUrl = window.URL.createObjectURL(blob);

                anchor.href = objectUrl;
                anchor.download = `mpid-${formatDateForExportName(new Date())}.csv`;
                anchor.click();

                // prevent memory leak by releasing memory
                window.setTimeout(() => {
                    try {
                        window.URL.revokeObjectURL(objectUrl);

                    } catch (e) {
                        console.error(e);
                    }
                }, 30 * 1000);
            })
            .catch(console.error)
            .finally(() => {
                setExporting(false);
            });
    };

    return (
        <div className="tp--export">
            <Button
                variant={exporting ? ButtonVariant.secondary : ButtonVariant.primary}
                isDisabled={disabled}
                onClick={showConfirmModal}
            >
                {exporting ? <Spinner/> : "Export"}
            </Button>
            {
                confirmShown && (
                    <Modal
                        title="Export Data"
                        className="tp--confirm-export"
                        isSmall
                        isOpen
                        onClose={hideConfirmModal}
                        isFooterLeftAligned
                        actions={[
                            <Button key="export" variant={ButtonVariant.primary} onClick={downloadExportedContent}>
                                Export
                            </Button>,
                            <Button key="cancel" variant={ButtonVariant.link} onClick={hideConfirmModal}>
                                Cancel
                            </Button>
                        ]}
                    >
                        Please confirm that you want to export current filtered results.
                    </Modal>
                )
            }
        </div>
    );
};

export default MemberSchemesExportButton;
