
import React, {useState, useContext} from "react";
import {PageHeader as PfPageHeader, Brand, Dropdown, DropdownToggle, DropdownItem} from "@patternfly/react-core";
import {useHistory} from "react-router-dom";


import AvatarIcon from "../../icons/AvatarIcon";
import DropDownIcon from "../../icons/DropDownIcon";
import {logOut, getUsername} from "../../services/loginService";
import {Routes} from "../../utils/constants";
import ChangePasswordModal from "./ChangePasswordModal";
import {ChangePasswordPayload} from "../../utils/types";
import {changePassword} from "../../services/userManagementService";
import {SharedContext} from "../../App";

export interface PageHeaderProps {
    onSidebarToggle: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({onSidebarToggle}) => {
    const {addSuccessToast, addErrorToast} = useContext(SharedContext);

    const [userKebabOpened, setUserKebabOpened] = useState(false);
    const [changePasswordModalOpened, setChangePasswordModalOpened] = useState(false);
    const history = useHistory();

    const onFocus = () => {
        const element = document.getElementById("page-header-user-kebab");
        element && element.focus();
    };

    const onKebabSelect = () => {
        setUserKebabOpened(!userKebabOpened);
        onFocus();
    };

    const openChangePasswordModal = () => setChangePasswordModalOpened(true);
    const closeChangePasswordModal = () => setChangePasswordModalOpened(false);

    const requestPasswordChange = (passwordData: ChangePasswordPayload) => {
        closeChangePasswordModal();

        changePassword(passwordData, history).then(json => {
            if (json.success) {
                addSuccessToast({
                    title: "Password change success", message: "Password is changed."
                });
            } else {
                addErrorToast({
                    title: "Password change failed", message: json.message || "Can't change password."
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "Password change failed", message: error.toString()
            });
        });
    };

    const logOutUser = e => {
        e.preventDefault();

        logOut();
        history.push(Routes.Login);
    };

    return (
        <PfPageHeader
            logo={
                <Brand src='/static/images/logo_landscape.svg' alt="My Pension ID logo"/>
            }
            avatar={(
                <>
                    <Dropdown
                        isOpen={userKebabOpened}
                        isPlain
                        onSelect={onKebabSelect}
                        direction="down"
                        position="right"
                        toggle={
                            <DropdownToggle
                                iconComponent={null}
                                onToggle={() => setUserKebabOpened(!userKebabOpened)}
                                aria-label="Applications"
                                id="page-header-user-kebab"
                            >
                                <span className="tp--header__user-name">{getUsername()}</span><DropDownIcon/>
                            </DropdownToggle>
                        }
                        dropdownItems={[
                            <DropdownItem key="changePassword" onClick={openChangePasswordModal}>Change password</DropdownItem>,
                            <DropdownItem key="logout" onClick={logOutUser}>Sign out</DropdownItem>
                        ]}
                    />
                    <AvatarIcon className="tp--header__avatar-icon"/>
                    {
                        changePasswordModalOpened &&
                        <ChangePasswordModal
                            onConfirm={requestPasswordChange}
                            onCancel={closeChangePasswordModal}
                        />
                    }
                </>
            )
            }
            showNavToggle
            onNavToggle={onSidebarToggle}
        />
    );
};

export default PageHeader;
