import React from "react";

const CheckIcon: React.FC<{ className?: string; fill?: string; title?: string }> = ({className, fill = "#92D400", title}) => {
    return (
        <span title={title}>
            <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M18 9C18 10.621 17.5948 12.121 16.7843 13.5C15.9738 14.879 14.879 15.9738 13.5 16.7843C12.121 17.5948 10.621 18 9 18C7.37903 18 5.87903 17.5948 4.5 16.7843C3.12097 15.9738 2.02621 14.879 1.21573 13.5C0.405242 12.121 0 10.621 0 9C0 7.37903 0.405242 5.87903 1.21573 4.5C2.02621 3.12097 3.12097 2.02621 4.5 1.21573C5.87903 0.405242 7.37903 0 9 0C10.621 0 12.121 0.405242 13.5 1.21573C14.879 2.02621 15.9738 3.12097 16.7843 4.5C17.5948 5.87903 18 7.37903 18 9ZM7.94758 13.754L14.625 7.07661C14.746 6.97984 14.8065 6.84677 14.8065 6.67742C14.8065 6.50806 14.746 6.375 14.625 6.27823L13.8266 5.44355C13.7056 5.32258 13.5665 5.2621 13.4093 5.2621C13.252 5.2621 13.1129 5.32258 12.9919 5.44355L7.54839 10.8871L5.00806 8.34677C4.8871 8.22581 4.74798 8.16532 4.59073 8.16532C4.43347 8.16532 4.29435 8.22581 4.17339 8.34677L3.375 9.18145C3.25403 9.27823 3.19355 9.41129 3.19355 9.58064C3.19355 9.75 3.25403 9.88306 3.375 9.97984L7.14919 13.754C7.24597 13.875 7.37903 13.9355 7.54839 13.9355C7.71774 13.9355 7.85081 13.875 7.94758 13.754Z"/>
            </svg>
        </span>
    );
};

export default CheckIcon;
