import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";

import {UNAUTHORIZED_ERR_NAME, UserRolesEnum} from "../../utils/constants";
import {User} from "../../utils/types";
import {getUsers} from "../../services/userManagementService";


export function fetchUsersHook(onResult: (users: User[]) => void) {
    const [cacheKey, setCacheKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const history = useHistory();

    const reload = () => setCacheKey(Date.now().toString());


    useEffect(() => {
        async function fetchUsers() {
            try {
                setLoading(true);
                setDone(false);

                const usersJson = await getUsers(history);
                const internalUsers: User[] = usersJson.users.map(user => {
                    let role: "Admin" | "Operator" | "" = "";

                    if (user.roles.includes(UserRolesEnum.ROLE_ADMIN)) {
                        role = "Admin";
                    } else if (user.roles.includes(UserRolesEnum.ROLE_OPERATOR)) {
                        role = "Operator";
                    }

                    return {
                        id: user.id,
                        email: user.email,
                        name: user.fullname,
                        active: user.active,
                        role
                    };
                });

                onResult(internalUsers);
                setLoading(false);
                setDone(true);

            } catch (error) {
                if (error && error.message !== UNAUTHORIZED_ERR_NAME) {
                    setLoading(false);
                }
            }
        }

        fetchUsers();
    }, [cacheKey]);

    return [loading, done, reload] as [boolean, boolean, () => void];
}
