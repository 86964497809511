import {History} from "history";

import {UNAUTHORIZED_ERR_NAME, Routes} from "../utils/constants";
import {getAuthToken, forceUpdateAuthToken} from "./loginService";


export interface ApiJsonResponse {
    // AUTH error response
    error?: string;
    error_description?: string;
    // internal service error response
    statusCode?: number;
    message?: string;
    timestamp?: number;
}

/**
 * Redirect to login screen when we cannot authorize user anymore
 */
function redirectToLogin(history: History) {

    history.push(Routes.Login);
    throw new Error(UNAUTHORIZED_ERR_NAME);
}


/**
 * Creates api call against secured endpoint
 */
export async function callSecuredApiEndpoint<R extends ApiJsonResponse>(
    requestConstructor: (authHeader: string) => Promise<R>,
    history: History
): Promise<R> {

    const token = await getAuthToken();

    // invalid token - e.g. even refresh has expired and user must auth again
    if (token === null) {
        redirectToLogin(history);
    }

    const json: R = await requestConstructor(`Bearer ${token}`);

    if (json.error) {
        // issue new token and try again
        if (json.error === "invalid_grant" || json.error === "invalid_token") {
            // force update token and rerun request
            await forceUpdateAuthToken();

            const updatedToken = await getAuthToken();
            const reloadedJson: R = await requestConstructor(`Bearer ${updatedToken}`);

            if (reloadedJson.error) {
                if (reloadedJson.error === "invalid_grant" || reloadedJson.error === "invalid_token") {
                    redirectToLogin(history);

                } else {
                    throw new Error(`Server Error: ${json.error}`);
                }
                return reloadedJson;
            }
        } else {
            throw new Error(json.message || json.error || "");
        }
    }

    return json;
}
