import React, {useState, useContext} from "react";
import {Page, PageSection} from "@patternfly/react-core";

import PageHeader from "./PageHeader";
import PageSidebar from "./PageSidebar";
import {BreadcrumbsItem} from "./PageBreadcrumb";
import {SharedContext} from "../../App";

export interface PageWrapperProps {
    id?: string;
    breadcrumbItems?: BreadcrumbsItem[];
    children: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({id, children}) => {

    const {sidebarOpened, setSidebarOpened} = useContext(SharedContext);
    const [classNames, setClassNames] = useState<string[]>([]);

    const onResize = ({mobileView}: { mobileView: boolean }) => {
        const classes = new Set(classNames);

        if (mobileView) {
            classes.add("tp--mobile-view");
        } else {
            classes.delete("tp--mobile-view");
        }

        setClassNames([...classes]);
    };

    const onSidebarToggle = () => {
        const nextSidebarOpened = !sidebarOpened;

        const classes = new Set(classNames);
        const isMobileView = classes.has("tp--mobile-view");

        // add sidebar opened class only if outside of mobile view
        // as mobile view overlays sidebar so it does not affect page content
        if (nextSidebarOpened && !isMobileView) {
            classes.add("tp--sidebar-opened");
        } else {
            classes.delete("tp--sidebar-opened");
        }

        setClassNames([...classes]);
        setSidebarOpened(nextSidebarOpened);
    };

    return (
        <Page
            id={id}
            header={<PageHeader onSidebarToggle={onSidebarToggle}/>}
            sidebar={<PageSidebar opened={sidebarOpened}/>}
            mainContainerId="main-content-page-layout-default-nav"
            onPageResize={onResize}

        >
            <PageSection variant="light" className={classNames.join(" ")}>
                {children}
            </PageSection>

        </Page>
    );
};

export default PageWrapper;
