import authTokenStore from "./authTokenStore";

// auth token related method
export const getAuthToken = authTokenStore.getToken;
export const forceUpdateAuthToken = authTokenStore.updateToken;
export const getUsername = authTokenStore.getUsername;
export const isAdmin = authTokenStore.isAdminRole;

// login related method
export const isLoggedIn = authTokenStore.hasToken;
export const logIn = authTokenStore.requestTokens;
export const logOut = authTokenStore.clearTokens;
