import React from "react";
import {Modal, Button, ButtonVariant} from "@patternfly/react-core";

export interface ConfirmDeleteModalProps {
    title: string;
    children: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({title, children, onCancel, onConfirm}) => (
    <Modal
        title={title}
        className="tp--confirm-delete"
        isSmall
        isOpen
        onClose={onCancel}
        isFooterLeftAligned
        actions={[
            <Button key="export" variant={ButtonVariant.primary} onClick={onConfirm}>
                Delete
            </Button>,
            <Button key="cancel" variant={ButtonVariant.link} onClick={onCancel}>
                Cancel
            </Button>
        ]}
    >
        {children}
    </Modal>
);

export default ConfirmDeleteModal;
