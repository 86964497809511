import React, {useReducer, useState, useContext} from "react";
import * as PF from "@patternfly/react-core";
import {SortByDirection} from "@patternfly/react-table";

import PageWrapper from "../common/page/PageWrapper";
import EmptyStateSpinner from "../common/screenStates/EmptyStateSpinner";
import NoMatchEmptyState from "../common/screenStates/NoMatchEmptyState";
import ErrorState from "../common/screenStates/ErrorState";

import {usersColumnsDefs} from "../utils/constants";

import {setPageAction, setPageSizeAction, setSortByAction, setUsersAction, setUserNameFilterAction, editUserAction, cancelEditUserAction} from "../reducers/actions/userManagementActions";
import {fetchUsersHook} from "../common/hooks/fetchUsersHook";
import {userManagementReducer, getUsersInitialState} from "../reducers/userManagementReducer";
import SortableUsersTable from "../common/components/user/SortableUsersTable";
import AddUserComponent, {UserCreationResult} from "../common/components/user/AddUserComponent";
import {blockUser, unblockUser, deleteUser} from "../services/userManagementService";
import {useHistory} from "react-router-dom";
import EditUserComponent from "../common/components/user/EditUserComponent";
import ConfirmDeleteModal from "../common/components/ConfirmDeleteModal";
import {SharedContext} from "../App";


const UserManagementPage: React.FC = () => {
    const {addSuccessToast, addErrorToast} = useContext(SharedContext);

    const [state, dispatch] = useReducer(userManagementReducer, getUsersInitialState());
    const [loading, done, reload] = fetchUsersHook(users => dispatch(setUsersAction(users)));
    const [userNameFilter, setUserNameFilter] = useState("");
    const [confirmDelete, setConfirmDelete] = useState<{ id: number; name: string } | null>(null);

    const history = useHistory();

    const onSorted = (index: number, direction: SortByDirection) => dispatch(
        setSortByAction({
            index,
            key: usersColumnsDefs[index].sortKey,
            direction
        })
    );

    const onPageSizeChanged = (event, pageSize) => {
        dispatch(setPageAction(1));
        dispatch(setPageSizeAction(pageSize));
    };

    const onPageChanged = (event, page) => dispatch(setPageAction(page));

    const onFilterByUserName = (value: string) => {
        setUserNameFilter(value);
    };

    const onSearch = (e: React.MouseEvent) => {
        e.preventDefault();

        dispatch(setUserNameFilterAction(userNameFilter));
    };


    const clearFilters = () => {
        setUserNameFilter("");
        dispatch(setUserNameFilterAction(""));
    };

    const onCreateUserResponse = (result: UserCreationResult) => {
        clearFilters();
        reload();

        if (result.success) {

            addSuccessToast({
                title: "User creation success",
                message: `User ${result.userName} created succesfully.`
            });

        } else {
            addErrorToast({
                title: "User creation failed",
                message: result.error
            });
        }
    };

    const editUser = (id: number) => {
        const userToEdit = state.pagedUsers.find(user => user.id === id);

        userToEdit && dispatch(editUserAction({
            ...userToEdit,
            role: userToEdit.role === "" ? "Operator" : userToEdit.role
        }));
    };

    const cancelEditUser = () => dispatch(cancelEditUserAction());


    const onUserEdited = (result: UserCreationResult) => {
        reload();
        cancelEditUser();

        if (result.success) {
            addSuccessToast({
                title: "User edit success",
                message: `User ${result.userName} changed succesfully.`
            });

        } else {
            addErrorToast({
                title: "User edit failed",
                message: result.error
            });
        }
    };


    const suspendUser = (id: number, name: string) =>
        blockUser(id.toString(), history).then(json => {

            if (json.success) {
                addSuccessToast({
                    title: "User blocking success", message: `User ${name} is inactive now`
                });
            } else {
                addErrorToast({
                    title: "User blocking failed", message: `Can't block user ${name}`
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "User blocking failed", message: error.toString()
            });

        }).finally(reload);


    const unsuspendUser = (id: number, name: string) =>
        unblockUser(id.toString(), history).then(json => {

            if (json.success) {
                addSuccessToast({
                    title: "User unblocking success", message: `User ${name} is active now`
                });
            } else {
                addErrorToast({
                    title: "User unblocking failed", message: `Can't unblock user ${name}`
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "User unblocking failed", message: error.toString()
            });

        }).finally(reload);


    const openConfirmDeleteDialog = (id: number, name: string) => setConfirmDelete({id, name});

    const hideConfirmDeleteDialog = () => setConfirmDelete(null);


    const removeUser = (id: number, name: string) => {
        hideConfirmDeleteDialog();

        deleteUser(id.toString(), history).then(json => {

            if (json.success) {
                addSuccessToast({
                    title: "User deletion success",
                    message: `User ${name} is removed now`
                });
            } else {
                addErrorToast({
                    title: "User deletion failed", message: `Can't delete remove ${name}`
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "User deletion failed", message: error.toString()
            });

        }).finally(reload);
    };

    const totalCount = state.filteredAndSortedUsers.length;

    return (
        <PageWrapper
            id="users-manager"
            breadcrumbItems={[{
                label: "Manage Users",
                subpath: "admin-manager"
            }]}
        >
            <PF.PageSection className="tp--page__header">
                <PF.Text component="h1" >Manage Users</PF.Text>
                <PF.Form className="tp--filters">
                    <PF.Toolbar>
                        <PF.ToolbarSection aria-label="Search field">
                            <PF.ToolbarGroup>
                                <PF.FormGroup label="User Name" fieldId="name">
                                    <PF.TextInput
                                        type="text"
                                        name="name"
                                        placeholder="Filter by User Name"
                                        aria-label="Filter by User Name"
                                        value={userNameFilter}
                                        onChange={onFilterByUserName}
                                        autoComplete="off"
                                    />
                                </PF.FormGroup>
                            </PF.ToolbarGroup>
                        </PF.ToolbarSection>
                        <PF.ToolbarSection aria-label="Buttons" className="tp--filter-buttons">
                            <PF.ToolbarGroup>
                                <PF.Button
                                    variant={PF.ButtonVariant.secondary}
                                    type="submit"
                                    onClick={onSearch}
                                >
                                    Search
                                </PF.Button>
                            </PF.ToolbarGroup>
                            <PF.ToolbarGroup>
                                <AddUserComponent onUserCreated={onCreateUserResponse}/>
                            </PF.ToolbarGroup>
                        </PF.ToolbarSection>
                    </PF.Toolbar>
                </PF.Form>
            </PF.PageSection>
            <PF.PageSection isFilled>
                <div className="tp--page__background">
                    <div className="tp--page__table-wrapper">
                        {loading && !done && <EmptyStateSpinner/>}
                        {!loading && !done && <ErrorState errorText="Something went wrong with the network. Please retry."/>}
                        {!loading && done && totalCount === 0 && <NoMatchEmptyState onClearAllFilters={clearFilters}/>}
                        {done && totalCount > 0 && (
                            <SortableUsersTable
                                columnsDefs={usersColumnsDefs}
                                data={state.pagedUsers}
                                sortBy={state.sortBy}
                                onSorted={onSorted}
                                onEdit={editUser}
                                onSuspend={suspendUser}
                                onUnsuspend={unsuspendUser}
                                onDelete={openConfirmDeleteDialog}
                            />
                        )}
                    </div>
                    <PF.Pagination
                        className="tp--table--pagination"
                        itemCount={totalCount}
                        perPage={state.pageSize}
                        page={state.page}
                        variant={PF.PaginationVariant.bottom}
                        dropDirection={PF.DropdownDirection.up}
                        onPerPageSelect={onPageSizeChanged}
                        onSetPage={onPageChanged}
                    />
                </div>
            </PF.PageSection>
            {
                confirmDelete !== null &&
                <ConfirmDeleteModal
                    title="Delete User"
                    onConfirm={() => removeUser(confirmDelete.id, confirmDelete.name)}
                    onCancel={hideConfirmDeleteDialog}
                >
                    <p>
                        Please confirm that you you&apos;d like to delete user <span className="tp--user-name">{confirmDelete.name}</span>.
                    </p>
                </ConfirmDeleteModal>
            }
            {
                state.editedUser &&
                <EditUserComponent
                    userId={state.editedUser.id}
                    user={state.editedUser}
                    hideEditUserDialog={cancelEditUser}
                    onUserEdited={onUserEdited}
                />
            }
        </PageWrapper>
    );
};

export default UserManagementPage;
