import React from "react";
import {useHistory} from "react-router-dom";

import {editUser} from "../../../services/userManagementService";
import {UserRolesEnum} from "../../../utils/constants";
import UserModal from "./UserModal";
import {EditUserData} from "../../../utils/types";

export type UserCreationResult = {
    success: true;
    userName: string;
} | {
    success: false;
    error: string;
}

export interface EditUserComponentProps {
    userId: number;
    user: EditUserData;
    onUserEdited: (result: UserCreationResult) => void;
    hideEditUserDialog: () => void;
}

const EditUserComponent: React.FC<EditUserComponentProps> = ({userId, user, onUserEdited, hideEditUserDialog}) => {
    const history = useHistory();

    const saveUser = (userData: EditUserData) => {

        editUser(userId.toString(),
            {
                fullname: userData.name,
                email: userData.email,
                active: userData.active,
                roles: [userData.role === "Operator" ? UserRolesEnum.ROLE_OPERATOR : UserRolesEnum.ROLE_ADMIN]
            },
            history).
            then(response => {

                // error case
                if (response.statusCode && response.message) {
                    onUserEdited({
                        success: false,
                        error: response.message
                    });
                } else {
                    onUserEdited({
                        success: true,
                        userName: userData.name
                    });
                }
            }).
            catch((error: Error) => {
                onUserEdited({
                    success: false,
                    error: error.message
                });
            });
    };

    return (
        <UserModal
            primaryButtonText="Save User"
            {...user}
            saveUser={saveUser}
            closeModal={hideEditUserDialog}
        />
    );
};

export default EditUserComponent;
