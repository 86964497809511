import React, {useState} from "react";
import {
    Button,
    ButtonVariant,
    Modal,
    Form,
    TextInput,
    FormGroup
} from "@patternfly/react-core";
import {ChangePasswordPayload} from "../../utils/types";


type ValidFields = {
    oldPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
}


export interface ChangePasswordModalProps {
    onConfirm: (payload: ChangePasswordPayload) => void;
    onCancel: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({onConfirm, onCancel}) => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [invalidPasswordText, setInvalidPasswordText] = useState("");

    // mark fields as touched if we are editing as value already exists
    const [touchedFields, setTouchedFields] = useState<ValidFields>({
        oldPassword: false, newPassword: false, confirmPassword: false
    });

    // mark fields valid if we are editing so user can save them wihout touching the fields
    const [validFields, setValidFields] = useState<ValidFields>({
        oldPassword: false, newPassword: false, confirmPassword: false
    });

    const updateFieldValidity = (fieldName: keyof ValidFields, isValid: boolean) => setValidFields({
        ...validFields,
        [fieldName]: isValid
    });

    const updateTouchedFields = (fieldName: keyof ValidFields) => setTouchedFields({
        ...touchedFields,
        [fieldName]: true
    });

    const updateOldPassword = (value: string) => setOldPassword(value);
    const validateOldPassword = () => {
        updateTouchedFields("oldPassword");
        updateFieldValidity("oldPassword", oldPassword.trim().length > 1);
    };

    const fastValidateNewPassword = (value: string) => {
        const upperCase = value.toLocaleUpperCase();
        const lowerCase = value.toLocaleLowerCase();

        if (value.length < 8) {
            setInvalidPasswordText("Password length must be at least 8 characters.");
            return false;
        } else if (/[0-9]/.test(value) === false) {
            setInvalidPasswordText("Password must contain at least one digit.");
            return false;
            // no upper case if lower-casing does not change string
        } else if (value === lowerCase) {
            setInvalidPasswordText("Password must contain at least one upper case letter.");
            return false;
        } else if (value === upperCase) {
            setInvalidPasswordText("Password must contain at least one lower case letter.");
            return false;
        }

        setInvalidPasswordText("");
        return true;
    };

    const updateNewPassword = (value: string) => {
        setNewPassword(value);
        updateFieldValidity("newPassword", fastValidateNewPassword(value));
    };

    const onNewPasswordFocus = () => {
        updateTouchedFields("newPassword");
    };

    const updateConfirmPassword = (value: string) => setConfirmPassword(value);
    const validateConfirmPassword = () => {
        updateTouchedFields("confirmPassword");
        updateFieldValidity("confirmPassword", confirmPassword === newPassword);
    };

    const sendChange = () => {
        onConfirm({
            oldPassword,
            newPassword,
            confirmPassword
        });
    };

    const hasInvalidField = () => Object.values(touchedFields).some(touched => !touched) ||
        Object.values(validFields).some(isValid => !isValid);

    return (

        <Modal
            title="Change password"
            isOpen
            isSmall
            onClose={onCancel}
            isFooterLeftAligned
            actions={[
                <Button key="add" isDisabled={hasInvalidField()} variant={ButtonVariant.primary} onClick={sendChange}>
                    Change Password
                </Button>,
                <Button key="cancel" variant={ButtonVariant.link} onClick={onCancel}>
                    Cancel
                </Button>
            ]}
        >
            <Form isHorizontal>
                <FormGroup
                    label="Current password"
                    isRequired
                    fieldId="oldPassword"
                >
                    <TextInput
                        isRequired
                        type="password"
                        id="oldPassword"
                        name="oldPassword"
                        value={oldPassword}
                        aria-label="Use current password"
                        isValid={!touchedFields.oldPassword || validFields.oldPassword}
                        onChange={updateOldPassword}
                        onBlur={validateOldPassword}
                    />
                </FormGroup>
                <FormGroup
                    label="New password"
                    isRequired
                    fieldId="newPassword"
                    validated="error"
                    helperTextInvalid={invalidPasswordText}
                >
                    <TextInput
                        isRequired
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        value={newPassword}
                        aria-describedby="name-helper"
                        isValid={!touchedFields.newPassword || validFields.newPassword}
                        onChange={updateNewPassword}
                        onFocus={onNewPasswordFocus}
                    />
                </FormGroup>
                <FormGroup
                    label="Confirm password"
                    isRequired
                    fieldId="confirmPassword"
                    validated="error"
                    helperTextInvalid={touchedFields.confirmPassword && !validFields.confirmPassword ? "Does not match new password" : ""}
                >
                    <TextInput
                        isRequired
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        aria-label="Confirm new password"
                        isValid={!touchedFields.confirmPassword || validFields.confirmPassword}
                        onChange={updateConfirmPassword}
                        onBlur={validateConfirmPassword}
                    />
                </FormGroup>

            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;
