type CacheRecord = {
    expiration: number;
    content: any;
};

class Cache {

    private cache = new Map<string, CacheRecord>();

    /**
     * Set cache
     */
    set(key: string, content: any, expiration = 3600 * 1000) {
        this.cache.set(key, {
            expiration: Date.now() + expiration,
            content
        });
    }

    private find<T>(key: string) {
        const record = this.cache.get(key);

        if (record && Date.now() <= record.expiration) {
            return record.content as T;
        }
        return null;
    }

    get<T>(key: string) {
        return this.find<T>(key);
    }

    has(key: string) {
        return this.find(key) !== null;
    }

    invalidate(key: string){
        this.cache.delete(key);
    }

    // debug purpose
    list(){
        Array.from(this.cache.entries()).forEach(entry => {
            const record = entry[1];
            console.log(entry[0], new Date(record.expiration), record.content);
        });
    }
}
export default new Cache();
