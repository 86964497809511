import {PaginationActions, setPageAction, setPageSizeAction, setSortByAction} from "./pagination";

export {setPageAction};
export {setPageSizeAction};
export {setSortByAction};

export const setSchemesFiltersQueryAction = (query: string) => ({
    type: "SET_SCHEMES_FILTERS_QUERY" as "SET_SCHEMES_FILTERS_QUERY",
    query
});

export const schemesReloadAction = (query: string) => ({
    type: "SCHEMES_FILTER_RELOAD" as "SCHEMES_FILTER_RELOAD",
    query
});

export const schemesFilterChangedAction = () => ({
    type: "SCHEMES_FILTER_CHANGED" as "SCHEMES_FILTER_CHANGED"
});

export const resetMemberSchemesStateAction = () => ({
    type: "RESET_SCHEMES_STATE" as "RESET_SCHEMES_STATE"
});

export type MemberSchemesActions = PaginationActions | ReturnType<typeof setSchemesFiltersQueryAction> |
ReturnType<typeof schemesFilterChangedAction> | ReturnType<typeof resetMemberSchemesStateAction> |
ReturnType<typeof schemesReloadAction>;
