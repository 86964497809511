import React from "react";

const ImportIcon: React.FC<{ className?: string }> = ({className}) => {
    return (
        <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V12H2V16H16V2H2V6H0V2C0 0.9 0.89 0 2 0ZM8.5 14L7.09 12.59L9.67 10H0V8H9.67L7.09 5.41L8.5 4L13.5 9L8.5 14Z"/>
        </svg>
    );
};

export default ImportIcon;
