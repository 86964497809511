

import React from "react";

const AddIcon: React.FC<{ fill?: string }> = ({fill = "#777E84"}) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M13.2132 13.2721C6.182 20.3032 6.182 31.6968 13.2132 38.7279C20.2443 45.7591 31.6378 45.7591 38.669 38.7279C45.7001 31.6968 45.7001 20.3032 38.669 13.2721C31.6378 6.24093 20.2443 6.24093 13.2132 13.2721ZM35.5228 23.1004C36.0052 23.1004 36.3953 23.4905 36.3953 23.9729L36.3902 28.0325C36.3902 28.5149 36.0001 28.905 35.5177 28.905L28.815 28.8742L28.8407 35.582C28.8407 36.0644 28.4506 36.4545 27.9682 36.4545L23.9086 36.4494C23.4262 36.4494 23.0361 36.0593 23.0361 35.5769L23.0669 28.8742L16.3591 28.8999C15.8767 28.8999 15.4866 28.5098 15.4866 28.0274V23.9627C15.4866 23.4802 15.8767 23.0902 16.3591 23.0902L23.0669 23.1261L23.0412 16.4183C23.0412 15.9359 23.4313 15.5458 23.9137 15.5458L27.9784 15.5458C28.4609 15.5458 28.8509 15.9359 28.8509 16.4183L28.815 23.1261L35.5228 23.1004Z"/>
        </svg>
    );
};

export default AddIcon;
