import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Brand, Text, Form, FormGroup, TextInput, Button, ButtonVariant, ActionGroup} from "@patternfly/react-core";

import EmptyStateSpinner from "../common/screenStates/EmptyStateSpinner";
import {Routes} from "../utils/constants";
import {resetPasswordRequest} from "../services/userManagementService";
import {OutlinedCheckCircleIcon, ErrorCircleOIcon} from "@patternfly/react-icons";


const ForgottenPasswordPage: React.FC = () => {
    const [connecting, setConnecting] = useState(false);
    const [result, setResult] = useState<"success" | "error" | null>(null);
    const [errorMessage, setErrorMessage] = useState("Something went wrong");

    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isTouchedEmail, setIsTouchedEmail] = useState(false);

    const history = useHistory();


    const updateEmail = (email: string) => {
        setIsTouchedEmail(true);
        setEmail(email.trim());
        setIsValidEmail(email.includes("@"));
    };


    const requestPasswordReset = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setConnecting(true);

        resetPasswordRequest({email})
            .then(json => {
                if (json.success ){
                    setResult("success");
                } else {
                    setResult("error");
                    setErrorMessage(json.message || json.error || "");
                }

            })
            .catch((error) => {
                setResult("error");
                setErrorMessage("Please enter well-formed email address.");
                console.warn(error);
            })
            .finally(() => {
                setConnecting(false);
            });
        return false;
    };

    const back = () => history.push(Routes.Login);


    return (
        <div className={`tp--login tp--forgotten-password ${result && "tp--result"}`}>
            <section className="tp--login__wrapper">
                <header className="tp--login__header">
                    <Brand src='static/images/logo_landscape_white.svg' alt="My Pension ID logo"/>
                </header>
                <div className="tp--login__heading">
                    <Text component="h1">Request password reset</Text>
                </div>
                {result === null &&
                    <Form className="tp--login__form">
                        <FormGroup
                            label="Email"
                            isRequired
                            fieldId="email"
                        >
                            <TextInput
                                isRequired
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                aria-describedby="email-helper"
                                isValid={!isTouchedEmail || isValidEmail}
                                onChange={updateEmail}
                            />
                        </FormGroup>

                        <ActionGroup>
                            <Button
                                variant={ButtonVariant.primary}
                                type="submit"
                                onClick={requestPasswordReset}
                            >
                                Send
                            </Button>
                            <Button
                                variant={ButtonVariant.link}
                                type="button"
                                onClick={back}
                            >
                                Back to Login
                            </Button>
                        </ActionGroup>
                    </Form>
                }
                {
                    result &&
                    <div className="tp--forgotten-password__result">
                        {
                            result === "success" &&
                            <div className="tp--forgotten-password__result__icon">
                                <OutlinedCheckCircleIcon/>
                                <Text component="h2">Success</Text>
                            </div>
                        }
                        {
                            result === "error" &&
                            <div className="tp--forgotten-password__result__icon">
                                <ErrorCircleOIcon className="tp--error"/>
                                <Text component="h2">Failure</Text>
                                <p>{errorMessage}</p>
                            </div>
                        }
                        <Button
                            variant={ButtonVariant.primary}
                            type="button"
                            onClick={back}
                        >
                            Back to Login
                        </Button>
                    </div>
                }
                {connecting && <EmptyStateSpinner/>}
            </section>
        </div>
    );
};

export default ForgottenPasswordPage;
