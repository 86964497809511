import React, {useState} from "react";
import {useHistory, Link} from "react-router-dom";
import {LoginForm, Brand, Text} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";

import EmptyStateSpinner from "../common/screenStates/EmptyStateSpinner";
import {logIn} from "../services/loginService";
import {LoginResultEnum, Routes} from "../utils/constants";

export interface LoginPageProps {
    from?: string;
}

const LoginPage: React.FC<LoginPageProps> = () => {
    const [connecting, setConnecting] = useState(false);

    const [username, setUsername] = useState("");
    const [isValidUsername, setIsValidUsername] = useState(false);

    const [password, setPassword] = useState("");
    const [invalidCredentials, setInvalidCredentials] = useState(false);

    const history = useHistory();

    const helperText = (
        <React.Fragment>
            <ExclamationCircleIcon/>&nbsp;Invalid login credentials.
        </React.Fragment>
    );

    const updateUsername = (email: string) => {
        setUsername(email.trim());
        setIsValidUsername(email.includes("@"));
        setInvalidCredentials(false);
    };

    const updatePassword = (password: string) => {
        setPassword(password.trim());
        setInvalidCredentials(false);
    };

    const createLogInRequest = (e) => {
        e.preventDefault();

        setConnecting(true);

        logIn(username, password).then(success => {


            if (success === LoginResultEnum.SUCCESS) {
                history.push("/member-manager");
            } else {
                setConnecting(false);

                if (LoginResultEnum.INVALID) {
                    setInvalidCredentials(true);

                    // @TODO - special message?
                } else {
                    setInvalidCredentials(true);
                }
            }
        });
    };

    const allValid = isValidUsername && password.length > 0;

    return (
        <div className="tp--login">
            <section className="tp--login__wrapper">
                <header className="tp--login__header">
                    <Brand src='static/images/logo_landscape_white.svg' alt="My Pension ID logo"/>
                </header>
                <div className="tp--login__heading">
                    <Text component="h1">Log in to the Dashboard</Text>
                </div>
                <LoginForm
                    className="tp--login__form"
                    showHelperText={invalidCredentials}
                    helperText={helperText}
                    usernameLabel="Email"
                    usernameValue={username}
                    onChangeUsername={updateUsername}
                    isValidUsername={isValidUsername}
                    passwordLabel="Password"
                    passwordValue={password}
                    onChangePassword={updatePassword}
                    isValidPassword={password.length > 0}
                    onLoginButtonClick={createLogInRequest}
                    isLoginButtonDisabled={!allValid}
                />
                {connecting && <EmptyStateSpinner/>}
                <footer className="tp--login__footer">
                    <Link to={Routes.ForgotPassword}>Forgot your password?</Link>
                </footer>
            </section>
        </div>
    );
};

export default LoginPage;
