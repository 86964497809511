import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Button,
    ButtonVariant,
    Spinner
} from "@patternfly/react-core";

import {createUser} from "../../../services/userManagementService";
import {UserRolesEnum} from "../../../utils/constants";
import UserModal from "./UserModal";
import {EditUserData} from "../../../utils/types";

export type UserCreationResult = {
    success: true;
    userName: string;
} | {
    success: false;
    error: string;
}

export interface AddUserComponentProps {
    onUserCreated: (result: UserCreationResult) => void;
}

const AddUserComponent: React.FC<AddUserComponentProps> = ({onUserCreated}) => {

    const [dialogOpened, setDialogOpened] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const history = useHistory();


    const showAddUserDialog = (e: React.MouseEvent) => {
        e.preventDefault();

        setDialogOpened(true);
    };

    const hideAddUserDialog = () => setDialogOpened(false);

    const addUser = (userData: EditUserData) => {
        setInProgress(true);
        setDialogOpened(false);

        createUser({
            fullname: userData.name,
            email: userData.email,
            active: userData.active,
            roles: [userData.role === "Operator" ? UserRolesEnum.ROLE_OPERATOR : UserRolesEnum.ROLE_ADMIN]
        }, history).
            then(response => {

                // error case
                if (response.statusCode && response.message) {
                    onUserCreated({
                        success: false,
                        error: response.message
                    });
                } else {
                    onUserCreated({
                        success: true,
                        userName: userData.name
                    });
                }
            }).
            catch((error: Error) => {
                onUserCreated({
                    success: false,
                    error: error.message
                });
            })
            .finally(() => {
                setInProgress(false);
            });

    };

    return (
        <div className="tp--add-user">
            <Button
                variant={inProgress ? ButtonVariant.secondary : ButtonVariant.primary}
                type="button"
                onClick={showAddUserDialog}
            >
                {inProgress ? <Spinner/> : "Create new"}
            </Button>
            {
                dialogOpened && (
                    <UserModal
                        primaryButtonText="Create User"
                        saveUser={addUser}
                        closeModal={hideAddUserDialog}
                    />
                )
            }
        </div>
    );
};

export default AddUserComponent;
