import React from "react";
import {Title, EmptyState, EmptyStateIcon, Spinner} from "@patternfly/react-core";

const EmptyStateSpinner: React.FC<{text?: string}> = ({text = "Loading"}) => (
    <EmptyState className="tp--spinner">
        <EmptyStateIcon variant="container" component={Spinner}/>
        <Title size="lg">
            {text}
        </Title>
    </EmptyState>
);

export default EmptyStateSpinner;
