import React, {useState, useImperativeHandle, forwardRef} from "react";
import {
    TextInput,
    FormGroup,
    Form,
    Toolbar,
    ToolbarGroup,
    ToolbarSection,
    Select,
    SelectVariant,
    SelectOption,
    SelectOptionObject,
    Button,
    ButtonVariant
} from "@patternfly/react-core";
import DatePicker from "react-datepicker";

import {convertDateToApiFormat} from "../../../utils/utils";
import {TpSchemeData, TpClientData} from "../../../services/membersSchemeService";

interface ClientAndMemberValue extends SelectOptionObject {
    id: string;
    name: string;
    toString: () => string;
}


const statusVerifiedOptions: Record<string, any> = {
    "Verified": <SelectOption key="Verified" value="Verified"/>,
    "Unverified": <SelectOption key="Unverified" value="Unverified"/>
};

export type MemberSchemesFilterPannelRef = {
    reset: () => void;
    search: () => void;
    getQuery: () => string;
};

export interface MemberSchemesFilterPannelProps {
    query: string;
    schemeOptions: TpSchemeData[];
    clientOptions: TpClientData[];
    onSearch: (query: string) => void;
    onFilterChange: () => void;
}

const MemberSchemesFilterPannel: React.FC<MemberSchemesFilterPannelProps> = ({
    query,
    clientOptions,
    schemeOptions,
    onSearch,
    onFilterChange
}, ref) => {

    // parse query so we can populate fields with initial values from it
    const params: Record<string, string> = query.split("&").reduce((accumulator, keyValue) => {
        const [key, value] = keyValue.split("=");

        if (key) {
            accumulator[key] = value;
        }
        return accumulator;
    }, {});

    const [name, setName] = useState(params.name || "");
    const [schemeId, setSchemeId] = useState(params.schemeId || "");
    const [clientId, setClientId] = useState(params.clientId || "");
    const [identifier, setIdentifier] = useState(params.identifier || "");

    const [projectNumber, setProjectNumber] = useState(params.projectNumber || "");
    const [verified, setVerified] = useState<"Verified" | "Unverified" | "">(
        params.verified ? params.verified === "true" ? "Verified" : "Unverified" : ""
    );
    const [regActivityFrom, setRegActivityFrom] = useState<Date | null>(
        params.regActivityFrom ? new Date(params.regActivityFrom) : null
    );
    const [regActivityTo, setRegActivityTo] = useState<Date | null>(
        params.regActivityTo ? new Date(params.regActivityTo) : null
    );


    const [statusDropdownExpanded, setStatusDropdownExpanded] = useState(false);
    const [clientDropdownExpanded, setClientDropdownExpanded] = useState(false);
    const [schemeDropdownExpanded, setSchemeDropdownExpanded] = useState(false);

    const schemes = schemeOptions.map(schemeData => {
        const value: ClientAndMemberValue = {
            name: schemeData.name,
            id: schemeData.id,
            toString: function() {
                return this.name;
            }
        };

        return {
            id: schemeData.id,
            name: schemeData.name,
            nameLowerCase: (schemeData.name || "").toLowerCase(),
            option: <SelectOption key={schemeData.id} value={value}/>
        };
    });

    // intentionally used soft comparison so we do not have to convert string id to number
    // when parsed from query params
    const schemeSelection = schemes.find(scheme => scheme.id == schemeId);

    const clients = clientOptions.map(clientData => {
        const value: ClientAndMemberValue = {
            name: clientData.name,
            id: clientData.id,
            toString: function() {
                return this.name;
            }
        };

        return {
            id: clientData.id,
            name: clientData.name,
            nameLowerCase: (clientData.name|| "").toLowerCase(),
            option: <SelectOption key={clientData.id} value={value}/>
        };
    });

    const clientSelection = clients.find(client => client.id == clientId);


    const onNameChange = (value: string) => {
        (value !== name) && onFilterChange();
        setName(value);
    };

    const onIdentifierChange = (value: string) => {
        (value !== identifier) && onFilterChange();
        setIdentifier(value);
    };

    const onProjectNumberChange = (value: string) => {
        (value !== projectNumber) && onFilterChange();
        setProjectNumber(value);
    };

    const onVerifiedChange = (e, selectionChange: string | SelectOptionObject) => {
        e.preventDefault();
        const value: any = selectionChange.toString();

        (value !== verified) && onFilterChange();

        setVerified(value);
        setStatusDropdownExpanded(false);
    };

    const onStatusCleared = () => {
        onFilterChange();
        setVerified("");
    };

    const onClientNameChange = (e, selectionChange: ClientAndMemberValue) => {
        e.preventDefault();

        const selectedClient = clients.find(client => client.id === selectionChange.id);

        if (selectedClient) {
            (selectedClient.id !== clientId) && onFilterChange();
            setClientId(selectedClient.id);
        }
        setClientDropdownExpanded(false);
    };

    const onClientNameCleared = () => {
        onFilterChange();
        setClientId("");
    };

    const onSchemeNameChange = (e, selectionChange: ClientAndMemberValue) => {
        e.preventDefault();

        const selectedScheme = schemes.find(scheme => scheme.id === selectionChange.id);

        if (selectedScheme) {
            (selectedScheme.id !== schemeId) && onFilterChange();
            setSchemeId(selectedScheme.id);
        }
        setSchemeDropdownExpanded(false);
    };

    const onSchemeNameCleared = () => {
        onFilterChange();
        setSchemeId("");
    };

    const onStatusFromChange = (value: Date) => {
        if (regActivityFrom && value) {
            value.toISOString() !== regActivityFrom.toISOString() && onFilterChange();
        }
        setRegActivityFrom(value);
    };

    const onStatusToChange = (value: Date) => {
        if (regActivityTo && value) {
            value.toISOString() !== regActivityTo.toISOString() && onFilterChange();
        }
        setRegActivityTo(value);
    };

    const getQuery = () => {
        const params: string[] = [];

        name && params.push(`name=${name}`);
        schemeId && params.push(`schemeId=${schemeId}`);
        clientId && params.push(`clientId=${clientId}`);
        identifier && params.push(`identifier=${identifier}`);

        projectNumber && params.push(`projectNumber=${projectNumber}`);
        // filter only if single state is selected both or none means we search for all verification states
        verified && params.push(`verified=${verified === "Verified" ? "true" : "false"}`);
        regActivityFrom && params.push(`regActivityFrom=${convertDateToApiFormat(regActivityFrom)}`);
        regActivityTo && params.push(`regActivityTo=${convertDateToApiFormat(regActivityTo)}`);

        return params.join("&");
    };

    const search = () => onSearch(getQuery());

    const onSearchClick = (e: React.MouseEvent) => {
        e.preventDefault();

        search();
    };

    const reset = () => {
        setName("");
        setSchemeId("");
        setClientId("");
        setIdentifier("");

        setProjectNumber("");
        setVerified("");
        setRegActivityFrom(null);
        setRegActivityTo(null);

        onFilterChange();
    };

    useImperativeHandle(ref, () => ({
        reset,
        search,
        getQuery
    }));

    // custom filtering to match on any substring
    const filterSchemeNames = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = (e.target.value || "").toLowerCase();
        const filteredSchemes = schemes.filter(scheme => scheme.nameLowerCase.indexOf(value) > -1);

        return filteredSchemes.map(scheme => scheme.option);
    };

    // custom filtering to match on any substring
    const filterClientNames = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = (e.target.value || "").toLowerCase();
        const filteredClients = clients.filter(scheme => scheme.nameLowerCase.indexOf(value) > -1);

        return filteredClients.map(scheme => scheme.option);
    };

    return (
        <Form className="tp--filters">
            <Toolbar>
                <ToolbarSection aria-label="Top row">
                    <ToolbarGroup>
                        <FormGroup label="Project Number" fieldId="projectNumber">
                            <TextInput
                                type="text"
                                name="projectNumber"
                                placeholder="Filter by Project Number"
                                aria-label="Filter by Project Number"
                                value={projectNumber}
                                onChange={onProjectNumberChange}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <FormGroup label="Scheme Name" fieldId="schemeName">
                            <Select
                                id="selectSchemeName"
                                name="schemeName"
                                variant={SelectVariant.typeahead}
                                aria-label="Select Scheme Name"
                                onSelect={onSchemeNameChange as any}
                                onToggle={setSchemeDropdownExpanded}
                                onClear={onSchemeNameCleared}
                                onFilter={filterSchemeNames}
                                isExpanded={schemeDropdownExpanded}
                                selections={schemeSelection && schemeSelection.name}
                                placeholderText="Select Scheme Name"
                            >
                                {schemes.map(scheme => scheme.option)}
                            </Select>
                        </FormGroup>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <FormGroup label="Client Name" fieldId="clientName">
                            <Select
                                id="selectClientName"
                                name="clientName"
                                variant={SelectVariant.typeahead}
                                aria-label="Select Client Name"
                                onSelect={onClientNameChange as any}
                                onToggle={setClientDropdownExpanded}
                                onClear={onClientNameCleared}
                                onFilter={filterClientNames}
                                isExpanded={clientDropdownExpanded}
                                selections={clientSelection && clientSelection.name}
                                placeholderText="Select Client Name"
                            >
                                {clients.map(client => client.option)}
                            </Select>
                        </FormGroup>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <FormGroup label="Member ID, URN, NINO or Security Code" fieldId="identifier">
                            <TextInput
                                type="text"
                                name="identifier"
                                placeholder="Filter by URN, NINO or Security Code"
                                aria-label="Filter by URN, NINO or Security Code"
                                value={identifier}
                                onChange={onIdentifierChange}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </ToolbarGroup>
                </ToolbarSection>
                <ToolbarSection aria-label="Bottom row">
                    <ToolbarGroup>
                        <FormGroup label="Member Name" fieldId="name">
                            <TextInput
                                type="text"
                                name="name"
                                placeholder="Filter by Member Name"
                                aria-label="Filter by Member Name"
                                value={name}
                                onChange={onNameChange}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <FormGroup label="Member Status" fieldId="status" className="tp--filters__status-group">
                            <Select
                                id="selectStatus"
                                variant={SelectVariant.typeahead}
                                aria-label="Select Status"
                                onSelect={onVerifiedChange}
                                onClear={onStatusCleared}
                                onToggle={setStatusDropdownExpanded}
                                isExpanded={statusDropdownExpanded}
                                selections={verified && verified}
                                placeholderText="Select Verification Status"
                            >
                                {Object.values(statusVerifiedOptions) as any}
                            </Select>
                        </FormGroup>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <FormGroup label="Status Changed From" fieldId="from" className="tp--filters__date-group">
                            <DatePicker
                                selected={regActivityFrom}
                                className="pf-c-form-control"
                                placeholderText="Status Changed From"
                                aria-label="Filter status changes from Date"
                                onChange={onStatusFromChange}
                                isClearable
                                maxDate={regActivityTo || new Date()}
                            />
                        </FormGroup>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <FormGroup label="Status Changed To" fieldId="to" className="tp--filters__date-group">
                            <DatePicker
                                selected={regActivityTo}
                                className="pf-c-form-control"
                                placeholderText="Status Changed To"
                                aria-label="Filter status changes to Date"
                                onChange={onStatusToChange}
                                minDate={regActivityFrom}
                                isClearable
                                maxDate={new Date()}
                            />
                        </FormGroup>
                    </ToolbarGroup>
                </ToolbarSection>
                <ToolbarSection aria-label="Action row" className="tp--filters__last-row">
                    <ToolbarGroup>
                        <Button
                            variant={ButtonVariant.secondary}
                            type="submit"
                            onClick={onSearchClick}
                        >
                            Search
                        </Button>
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <Button
                            variant={ButtonVariant.link}
                            type="reset"
                            onClick={reset}
                        >
                            Reset
                        </Button>
                    </ToolbarGroup>
                </ToolbarSection>
            </Toolbar>
        </Form>
    );

};

export default forwardRef(MemberSchemesFilterPannel);
