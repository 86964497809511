import React from "react";

const DropDownIcon: React.FC<{ className?: string }> = ({className}) => {
    return (
        <svg className={className} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.46875 6.21875L0.21875 1.96875C0.0729165 1.82292 0 1.64583 0 1.4375C0 1.22917 0.0729165 1.05208 0.21875 0.90625L0.9375 0.21875C1.08333 0.0729165 1.26042 0 1.46875 0C1.67708 0 1.85417 0.0729165 2 0.21875L5 3.21875L8 0.21875C8.14583 0.0729165 8.32292 0 8.53125 0C8.73958 0 8.91667 0.0729165 9.0625 0.21875L9.78125 0.90625C9.92708 1.05208 10 1.22917 10 1.4375C10 1.64583 9.92708 1.82292 9.78125 1.96875L5.53125 6.21875C5.38542 6.36458 5.20833 6.4375 5 6.4375C4.79167 6.4375 4.61458 6.36458 4.46875 6.21875Z" fill="#777E84"/>
        </svg>
    );
};

export default DropDownIcon;


