import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";

import {UNAUTHORIZED_ERR_NAME} from "../../utils/constants";
import {getDetail} from "../../services/memberDetailService";
import {MemberSchemeDetail} from "../../utils/types";
import {normalizeProfileData, normalizeProjectData} from "../../utils/utils";


export function fetchDetailHook(id: string) {
    const [result, setResult] = useState<MemberSchemeDetail | null>(null);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const history = useHistory();


    useEffect(() => {
        async function fetchDetail() {
            try {
                setLoading(true);
                setDone(false);

                const detailJson = await getDetail(id, history);

                const scheme = detailJson.schemeProfiles[0] || null;

                const normalizedDetail: MemberSchemeDetail = {
                    uuid: detailJson.uuid || "",
                    schemeName: "",
                    nationalInsuranceNumber: detailJson.nationalInsuranceNumber || "",
                    urn: "",
                    clientReferenceNumber: "",
                    securityCode: detailJson.securityCode || "",
                    originalProfile: null,
                    updatedProfile: null,
                    project: normalizeProjectData(scheme && scheme.project ? scheme.project : {})
                };

                if (scheme) {
                    normalizedDetail.urn = scheme.urn || "";
                    normalizedDetail.clientReferenceNumber = scheme.clientReferenceNumber || "";

                    if (scheme.scheme) {
                        normalizedDetail.schemeName = scheme.scheme.name || "";
                    }

                    if (scheme.originalProfile) {
                        normalizedDetail.originalProfile = normalizeProfileData(scheme.originalProfile);
                    }

                    if (scheme.updatedProfile) {
                        normalizedDetail.updatedProfile = normalizeProfileData(scheme.updatedProfile);
                    }
                }


                setResult(normalizedDetail);
                setLoading(false);
                setDone(true);

            } catch (error) {
                if (error && error.message !== UNAUTHORIZED_ERR_NAME) {
                    setLoading(false);
                }
            }
        }

        fetchDetail();
    }, []);

    return [result, loading, done] as [MemberSchemeDetail, boolean, boolean];
}
