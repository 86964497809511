import React, {useReducer, useEffect, createContext, useState, useRef} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";

import PrivateRoute from "./common/components/PrivateRoute";
import ManageMembersPage from "./pages/MemberSchemesPage";
import LoginPage from "./pages/LoginPage";
import {Routes} from "./utils/constants";
import MemberSchemeDetailPage from "./pages/MemberSchemeDetailPage";
import {memberSchemesStateReducer, getMemberSchemesInitialState} from "./reducers/memberSchemesReducer";
import {resetMemberSchemesStateAction} from "./reducers/actions/memberSchemesActions";
import ImportMemberSchemesPage from "./pages/ImportMemberSchemes";
import UserManagementPage from "./pages/UserManagementPage";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import RestrictedPage from "./pages/RestrictedPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import AdminRoute from "./common/components/AdminRoute";
import ToastGroup, {ToastGroupRef, AddToastCall} from "./common/components/ToastGroup";

export interface SharedContextInterface {
    sidebarOpened: boolean;
    setSidebarOpened: (value: boolean) => void;
    addSuccessToast: AddToastCall;
    addErrorToast: AddToastCall;
}
export const SharedContext = createContext<SharedContextInterface>({
    sidebarOpened: false,
    setSidebarOpened: () => undefined,
    addSuccessToast: () => undefined,
    addErrorToast: () => undefined
});

const App: React.FC = () => {

    const [membersSchemeState, memberSchemesDispatch] = useReducer(memberSchemesStateReducer, getMemberSchemesInitialState());
    const [sidebarOpened, setSidebarOpened] = useState(false);

    const toastGroup = useRef<ToastGroupRef>(null);
    const location = useLocation();


    useEffect(() => {
        // reset query state once we leave member schemes section - otherwise keep state
        if (!location.pathname.startsWith(Routes.MemberSchemes)) {
            memberSchemesDispatch(resetMemberSchemesStateAction());
        }
    }, [location]);

    const addSuccessToast = (toast: Parameters<AddToastCall>[0]) => toastGroup.current && toastGroup.current.addSuccessToast(toast);
    const addErrorToast = (toast: Parameters<AddToastCall>[0]) => toastGroup.current && toastGroup.current.addErrorToast(toast);


    return (
        <SharedContext.Provider value={{sidebarOpened, setSidebarOpened, addSuccessToast, addErrorToast}}>
            <Switch>
                <Route path={Routes.Login}>
                    <LoginPage/>
                </Route>
                <Route path={Routes.ForgotPassword}>
                    <ForgottenPasswordPage/>
                </Route>

                <PrivateRoute path={Routes.MemberSchemesDetail + ":id"}>
                    <MemberSchemeDetailPage/>
                </PrivateRoute>
                <PrivateRoute path={Routes.MemberSchemes}>
                    <ManageMembersPage dispatch={memberSchemesDispatch} state={membersSchemeState}/>
                </PrivateRoute>
                <PrivateRoute path={Routes.Import}>
                    <ImportMemberSchemesPage/>
                </PrivateRoute>
                <AdminRoute path={Routes.AdminManager}>
                    <UserManagementPage/>
                </AdminRoute >

                <Route path={Routes.PageNotFound}>
                    <PageNotFoundPage/>
                </Route>
                <Route path={Routes.RestrictedArea}>
                    <RestrictedPage/>
                </Route>

                <Redirect from="" to={Routes.MemberSchemes} key="default-route"/>
            </Switch >
            <ToastGroup ref={toastGroup}/>
        </SharedContext.Provider>
    );
};


export default App;
