import React from "react";
import {
    Text,
    PageSection,
    Button
} from "@patternfly/react-core";

import PageWrapper from "../common/page/PageWrapper";
import {useParams, useHistory} from "react-router-dom";
import {fetchDetailHook} from "../common/hooks/fetchDetailHook";
import EmptyStateSpinner from "../common/screenStates/EmptyStateSpinner";
import ErrorState from "../common/screenStates/ErrorState";
import BackIcon from "../icons/BackIcon";
import DetailField from "../common/components/detail/DetailHeaderField";
import DetailProfile from "../common/components/detail/DetailProfile";
import SchemeDetail from "../common/components/detail/SchemeProfile";


const MemberSchemeDetailPage: React.FC = () => {

    const params = useParams<{ id: string }>();
    const [data, loading, done] = fetchDetailHook(params.id);
    const history = useHistory();

    const goBack = (e: React.MouseEvent) => {
        e.preventDefault();
        history.goBack();
    };

    return (
        <PageWrapper
            id="detail-page"
        >
            <PageSection className="tp--page__header">
                <Button
                    type="button"
                    variant="plain"
                    aria-label="Back to table"
                    onClick={goBack}
                >
                    <BackIcon/>
                </Button>
                <Text component="h1">Manage Member Schemes</Text>
                <section>
                    <DetailField label="Member ID" value={data ? data.uuid : ""}/>
                    <DetailField label="Security Code" value={data ? data.securityCode : ""}/>
                    <DetailField label="National Insurance Number" value={data ? data.nationalInsuranceNumber : ""}/>
                    <DetailField  label="URN" value={data ? data.urn : ""}/>
                </section>
            </PageSection>
            <PageSection isFilled>
                {loading && !done && <EmptyStateSpinner/>}
                {!loading && !done && <ErrorState title="No detail found" errorText="Something went wrong with the network. Please retry."/>}
                {
                    data && (
                        <>
                            <SchemeDetail detail={data}/>
                            {data.updatedProfile && <DetailProfile title="Updated Profile" profile={data.updatedProfile}/>}
                            {data.originalProfile && <DetailProfile title="Original Profile" profile={data.originalProfile}/>}

                        </>
                    )
                }
            </PageSection>
        </PageWrapper>
    );
};

export default MemberSchemeDetailPage;
