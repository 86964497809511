import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";

import {getClients, TpClientData} from "../../services/membersSchemeService";
import {UNAUTHORIZED_ERR_NAME, CLIENT_DROPDOWN_CACHE_KEY, DROPDOWNS_CACHE_TIME} from "../../utils/constants";
import ApiCache from "../../services/apiCache";


export function fetchClientNamesHook() {
    const [result, setResult] = useState<TpClientData[]>([]);
    const history = useHistory();

    useEffect(() => {
        async function fetchClients() {
            try {

                const clientsJson = await getClients(history);
                const clients: TpClientData[] = clientsJson.clients || [];

                ApiCache.set(CLIENT_DROPDOWN_CACHE_KEY, clients, DROPDOWNS_CACHE_TIME);

                setResult(clients);

            } catch (error) {
                if (error && error.message !== UNAUTHORIZED_ERR_NAME) {
                    console.error(error);
                }
            }
        }
        const cachedContent = ApiCache.get<TpClientData[]>(CLIENT_DROPDOWN_CACHE_KEY);

        if (cachedContent) {
            setResult(cachedContent);
        } else {
            fetchClients();
        }
    }, []);

    return [result];
}
