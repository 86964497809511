import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";

import {getSchemes, TpSchemeData} from "../../services/membersSchemeService";
import {UNAUTHORIZED_ERR_NAME, SCHEMES_DROPDOWN_CACHE_KEY, DROPDOWNS_CACHE_TIME} from "../../utils/constants";
import ApiCache from "../../services/apiCache";


export function fetchSchemeNamesHook() {
    const [result, setResult] = useState<TpSchemeData[]>([]);
    const history = useHistory();

    useEffect(() => {
        async function fetchSchemes() {
            try {

                const clientsJson = await getSchemes(history);
                const schemes = clientsJson.schemes || [];

                ApiCache.set(SCHEMES_DROPDOWN_CACHE_KEY, schemes, DROPDOWNS_CACHE_TIME);

                setResult(schemes);

            } catch (error) {
                if (error && error.message !== UNAUTHORIZED_ERR_NAME) {
                    console.error(error);
                }
            }
        }

        const cachedContent = ApiCache.get<TpSchemeData[]>(SCHEMES_DROPDOWN_CACHE_KEY);

        if (cachedContent) {
            setResult(cachedContent);
        } else {
            fetchSchemes();
        }

    }, []);

    return [result];
}
