import {History} from "history";

import {API_BASE_URL, UserRolesEnum} from "../utils/constants";
import {ApiJsonResponse, callSecuredApiEndpoint} from "./apiCalls";
import {UserRaw, ChangePasswordPayload, ResetPasswordPayload} from "../utils/types";


export interface UserRequestJson extends ApiJsonResponse {
    users: UserRaw[];
}


const requestUsers = (authorization: string) => fetch(
    `${API_BASE_URL}users`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "GET"
    }
).then(response => response.json()) as Promise<UserRequestJson>;

export const getUsers = (history: History) => callSecuredApiEndpoint(requestUsers, history);

export interface CreateUserData {
    email: string;
    fullname: string;
    active: boolean;
    roles: [UserRolesEnum];
}

const createUserRequest = (userData: CreateUserData) => (authorization: string) => fetch(
    `${API_BASE_URL}users`,
    {
        headers: new window.Headers({
            "Content-Type": "application/json",
            Authorization: authorization
        }),
        body: JSON.stringify(userData),
        method: "POST"
    }
).then(response => response.json()) as Promise<UserRequestJson>;

export const createUser = (userData: CreateUserData, history: History) => callSecuredApiEndpoint(createUserRequest(userData), history);

const editUserRequest = (id: string, userData: CreateUserData) => (authorization: string) => fetch(
    `${API_BASE_URL}users/${id}`,
    {
        headers: new window.Headers({
            "Content-Type": "application/json",
            Authorization: authorization
        }),
        body: JSON.stringify(userData),
        method: "PUT"
    }
).then(response => response.json()) as Promise<UserRequestJson>;

export const editUser = (id: string, userData: CreateUserData, history: History) => callSecuredApiEndpoint(editUserRequest(id, userData), history);

export interface UserActionResponse extends ApiJsonResponse {
    success: boolean;
}

const blockUserRequest = (userId: string) => (authorization: string) => fetch(
    `${API_BASE_URL}users/${userId}/block`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "PUT"
    }
).then(response => {

    if (response.status === 204) {
        return {
            success: true
        };
    } else {
        return response.json();
    }
}) as Promise<UserActionResponse>;

export const blockUser = (id: string, history: History) => callSecuredApiEndpoint(blockUserRequest(id), history);


const unblockUserRequest = (userId: string) => (authorization: string) => fetch(
    `${API_BASE_URL}users/${userId}/unblock`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "PUT"
    }
).then(response => {

    if (response.status === 204) {
        return {
            success: true
        };
    } else {
        return response.json();
    }
}) as Promise<UserActionResponse>;


export const unblockUser = (id: string, history: History) => callSecuredApiEndpoint(unblockUserRequest(id), history);

const deleteUserRequest = (userId: string) => (authorization: string) => fetch(
    `${API_BASE_URL}users/${userId}`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "DELETE"
    }
).then(response => {

    if (response.status === 204) {
        return {
            success: true
        };
    } else {
        return response.json();
    }
}) as Promise<UserActionResponse>;

export const deleteUser = (id: string, history: History) => callSecuredApiEndpoint(deleteUserRequest(id), history);


const changePasswordRequest = (passwordData: ChangePasswordPayload) => (authorization: string) => fetch(
    `${API_BASE_URL}users/password`,
    {
        headers: new window.Headers({
            Authorization: authorization,
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(passwordData),
        method: "PUT"
    }
).then(response => {

    if (response.status === 204) {
        return {
            success: true
        };
    } else {
        return response.json();
    }
}) as Promise<UserActionResponse>;

export const changePassword = (passwordData: ChangePasswordPayload, history: History) => callSecuredApiEndpoint(changePasswordRequest(passwordData), history);

export const resetPasswordRequest = (resetData: ResetPasswordPayload) => fetch(
    `${API_BASE_URL}users/password/reset`,
    {
        headers: new window.Headers({
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(resetData),
        method: "POST"
    }
).then(response => {
    if (response.status === 204) {
        return {
            success: true
        };
    } else {
        return response.json();
    }
}) as Promise<UserActionResponse>;


