import {ColumnDef} from "./types";

//const PROTOCOL = "https";
//const DOMAIN = "dev.mypensionid.co.uk";
//export const API_BASE_URL = `${PROTOCOL}://${DOMAIN}/api/`;
export const API_BASE_URL = "/api/";

export const DROPDOWNS_CACHE_TIME = 900 * 1000; // 15 minutes
export const CLIENT_DROPDOWN_CACHE_KEY = "clients_dropdown";
export const SCHEMES_DROPDOWN_CACHE_KEY = "schemes_dropdown";

export enum UserRolesEnum {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_OPERATOR = "ROLE_OPERATOR"
}

export enum LoginResultEnum {
    SUCCESS,
    INVALID,
    ERROR
}

export const UNAUTHORIZED_ERR_NAME = "Unauthorized";

export enum Routes {
    Login = "/login",
    ForgotPassword = "/forgot-password",
    MemberSchemes = "/member-schemes",
    MemberSchemesDetail = "/member-schemes/detail/",
    Import = "/import",
    AdminManager = "/admin-manager",
    PageNotFound = "/page-not-found",
    RestrictedArea ="/restricted-area"
}

export enum StatusVerifiedState {
    VERIFIED = "Verified",
    UNVERIFIED = "Unverified"
}


export const memberSchemesColumsDefs: ColumnDef[] = [
    {title: "Member ID", key: "memberId", type: "string", sortable: false, sortKey: ""},
    {title: "Member Name", key: "name", type: "string", sortable: true, sortKey: "name"},
    {title: "Gender", key: "gender", type: "string", sortable: true, sortKey: "sex"},
    {title: "Member NIN", key: "nin", type: "string", sortable: true, sortKey: "nationalInsuranceNumber"},
    {title: "Security code", key: "securityCode", type: "string", sortable: true, sortKey: "securityCode"},
    {title: "URN", key: "urn", type: "string", sortable: true, sortKey: "urn"},
    {title: "Scheme Name", key: "schemeName", type: "string", sortable: true, sortKey: "schemeName"},
    {title: "Client Name", key: "clientName", type: "string", sortable: true, sortKey: "clientName"},
    {title: "Status", key: "verified", type: "boolean", sortable: false, sortKey: ""}
];

export const usersColumnsDefs: ColumnDef[] = [
    {title: "User ID", key: "id", type: "number", sortable: true, sortKey: "id"},
    {title: "User Name", key: "name", type: "string", sortable: true, sortKey: "name"},
    {title: "Email", key: "email", type: "string", sortable: true, sortKey: "email"},
    {title: "Role", key: "role", type: "string", sortable: true, sortKey: "role"},
    {title: "Status", key: "active", type: "boolean", sortable: true, sortKey: "active"}
];
