import React from "react";
import {Redirect, Route} from "react-router-dom";

import {isLoggedIn, isAdmin} from "../../services/loginService";
import {Routes} from "../../utils/constants";


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const AdminRoute: React.FC<any> = ({children, ...rest}) => {
    return (
        <Route
            {...rest}
            render={({location}) =>
                isLoggedIn() && isAdmin() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.RestrictedArea,
                            state: {from: location.pathname}
                        }}
                    />
                )
            }
        />
    );
};

export default AdminRoute;
