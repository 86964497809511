import React, {useState, useRef, useEffect} from "react";
import * as PF from "@patternfly/react-core";
import {CheckCircleIcon} from "@patternfly/react-icons";
import {useHistory} from "react-router-dom";

import PageWrapper from "../common/page/PageWrapper";
import AddIcon from "../icons/AddIcon";
import {uploadFile, getImportStatus} from "../services/importService";


const ImportMemberSchemesPage: React.FC = () => {

    const [supportsBankDetails, setSupportsBankDetails] = useState(false);
    const [supportsSpouseDetails, setSupportsSpouseDetails] = useState(false);

    const [dragOver, setDragOver] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState("");

    const [pendingImportId, setPendingImportId] = useState<string | null>(null);
    const [importedFileName, setImportedFileName] = useState("");

    const [shownAlertType, showAlertType] = useState<"SUCCESS" | "FAILURE" | null>(null);

    const [failureReason, setFailureReason] = useState<string[]>([]);

    const history = useHistory();
    const inputRef = useRef<HTMLInputElement | null>(null);

    let pollingInterval: any = null;

    const pollUploadState = () => {
        pendingImportId !== null && getImportStatus(pendingImportId, history)
            .then(response => {
                if (response.status === "SUCCESS" || response.status === "FAILURE") {

                    // stop polling, clear imports and show result
                    window.clearInterval(pollingInterval);
                    pollingInterval = null;
                    setPendingImportId(null);
                    showAlertType(response.status);
                    setImportedFileName(fileName);
                    setFileName("");

                    response.status === "FAILURE" && setFailureReason(response.validationErrors);
                }
            });
    };

    useEffect(() => {
        if (pendingImportId !== null) {
            pollingInterval = window.setInterval(pollUploadState, 2000);
            setFile(null);
        }
    }, [pendingImportId]);

    const onSupportBankDetailsChange = (value: boolean) => setSupportsBankDetails(value);

    const onSupportSpouseDetailsChange = (value: boolean) => setSupportsSpouseDetails(value);


    const onDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    };

    const onDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        setDragOver(false);
    };

    const onInputUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.files) {
            const file = e.target.files[0];
            setFile(file);
            setFileName(file.name);
        }
    };

    const onDropUpload = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files) {
            const file = e.dataTransfer.files[0];
            setFile(file);
            setFileName(file.name);
            showAlertType(null);
        }
    };

    const importFile = () => uploadFile(file, {supportsBankDetails, supportsSpouseDetails}, history)
        .then(feedback => setPendingImportId(feedback.id.toString()));

    const closeAlert = () => showAlertType(null);

    return (
        <PageWrapper
            id="import-page"
        >
            <PF.PageSection className="tp--page__header">
                <PF.Text component="h1">Import Member Schemes</PF.Text>
                <PF.Form>
                    <PF.Toolbar>
                        <PF.ToolbarSection aria-label="Settings">
                            <PF.ToolbarGroup>
                                <PF.FormGroup
                                    label="Supports Bank Details"
                                    fieldId="bank"
                                >
                                    <PF.Switch
                                        id="bank"
                                        name="bank"
                                        isChecked={supportsBankDetails}
                                        aria-label="Select if import supports bank details"
                                        onChange={onSupportBankDetailsChange}
                                    />
                                </PF.FormGroup>
                            </PF.ToolbarGroup>
                            <PF.ToolbarGroup>
                                <PF.FormGroup
                                    label="Supports Spouse Details"
                                    fieldId="spouse"
                                >
                                    <PF.Switch
                                        id="spouse"
                                        name="spouse"
                                        isChecked={supportsSpouseDetails}
                                        aria-label="Select if import supports spouse details"
                                        onChange={onSupportSpouseDetailsChange}
                                    />
                                </PF.FormGroup>
                            </PF.ToolbarGroup>
                        </PF.ToolbarSection>
                    </PF.Toolbar>
                </PF.Form>
            </PF.PageSection>
            <PF.PageSection>
                <div className="tp--import__wrapper">
                    <div
                        className={`tp--import__upload-box ${dragOver ? "tp--drag-over" : ""}`}
                        onDragEnter={onDragEnter}
                        onDragOver={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDropUpload}
                    >
                        <input
                            className="tp--import__input"
                            type="file"
                            id="csvInputUpload"
                            name="csvInputUpload"
                            ref={inputRef}
                            onChange={onInputUpload}
                        />
                        <div className="tp--import__content">
                            {!fileName &&
                                <span
                                    className="tp--import__icon"
                                    onClick={() => inputRef.current && inputRef.current.click()}
                                >
                                    <AddIcon/>
                                </span>
                            }
                            {fileName &&
                                <span className="tp--import__icon">
                                    <CheckCircleIcon className="tp--check-icon"/>
                                </span>
                            }
                            <div className="tp--import__text">
                                {fileName && <p>{fileName}</p>}
                                <span>Drag or</span>
                                <label htmlFor="csvInputUpload">&nbsp;select&nbsp;</label>
                                <span>single file to import</span>

                            </div>
                        </div>
                    </div>
                    {
                        shownAlertType === "SUCCESS" &&
                        <PF.Alert
                            title="Import succesful"
                            variant={PF.AlertVariant.success}
                            isInline
                            action={<PF.AlertActionCloseButton onClose={closeAlert}/>}
                        >
                            <p>File<span className="tp--bold">&nbsp;{importedFileName}&nbsp;</span>is processed.</p>
                        </PF.Alert>
                    }
                    {
                        shownAlertType === "FAILURE" &&
                        <PF.Alert
                            title="Import failed"
                            variant={PF.AlertVariant.danger}
                            isInline
                            action={<PF.AlertActionCloseButton onClose={closeAlert}/>}
                        >
                            <p>Failed to process file<span className="tp--bold">&nbsp;{importedFileName}&nbsp;</span>for following reasons:</p>
                            {failureReason.map((reason, index) => <p key={index}>{reason}</p>)}
                        </PF.Alert>
                    }
                    <footer className="tp--import__footer">
                        <PF.Button
                            variant={pendingImportId ? PF.ButtonVariant.secondary : PF.ButtonVariant.primary}
                            isDisabled={file === null ? true : false}
                            onClick={importFile}
                        >
                            {pendingImportId ? <PF.Spinner/> : "Import CSV"}
                        </PF.Button>
                    </footer>
                </div>
            </PF.PageSection>
        </PageWrapper>
    );
};

export default ImportMemberSchemesPage;
