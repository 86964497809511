import React from "react";
import {Modal, ButtonVariant, Button} from "@patternfly/react-core";

export interface PushNotificationModalProps {
    title: string;
    children: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

const PushNotificationModal: React.FC<PushNotificationModalProps> = ({title, children, onCancel, onConfirm}) => (
    <Modal
        title={title}
        isSmall
        isOpen
        onClose={onCancel}
        actions={[
            <Button key="send" variant={ButtonVariant.primary} onClick={onConfirm}>
                Send
            </Button>,
            <Button key="cancel" variant={ButtonVariant.link} onClick={onCancel}>
                Cancel
            </Button>
        ]}
    >
        {children}
    </Modal>
);

export default PushNotificationModal;
