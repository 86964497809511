import React from "react";
import {EmptyState, EmptyStateBody, EmptyStateIcon, Title} from "@patternfly/react-core";
import {UserAltSlashIcon} from "@patternfly/react-icons";

import PageWrapper from "../common/page/PageWrapper";
import {Link} from "react-router-dom";
import {Routes} from "../utils/constants";

const RestrictedPage: React.FC = () => {

    return (
        <PageWrapper  id="restricted-area">
            <EmptyState variant="full">
                <EmptyStateIcon icon={UserAltSlashIcon}/>
                <Title headingLevel="h1" size="2xl">
                    Restricted page
                </Title>
                <EmptyStateBody>
                    <p>Requested page is not allowed for your role.</p>
                    <p>If you think this is a mistake please contact your administrator</p>
                    <p className="tp--go-home-link">
                        <Link to={Routes.MemberSchemes}>Return back to home page</Link>
                    </p>
                </EmptyStateBody>
            </EmptyState>
        </PageWrapper>
    );
};

export default RestrictedPage;
