import React from "react";

const BackIcon: React.FC<{ className?: string; fill?: string }> = ({className, fill = "#777E84"}) => {
    return (
        <svg className={className} width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M0.30625 6.25625L6.25625 0.30625C6.46042 0.102083 6.70833 0 7 0C7.29167 0 7.53958 0.102083 7.74375 0.30625L8.70625 1.3125C8.91042 1.51667 9.0125 1.76458 9.0125 2.05625C9.0125 2.34792 8.91042 2.58125 8.70625 2.75625L4.50625 7L8.70625 11.2C8.91042 11.4042 9.0125 11.6521 9.0125 11.9438C9.0125 12.2354 8.91042 12.4833 8.70625 12.6875L7.74375 13.6938C7.53958 13.8979 7.29167 14 7 14C6.70833 14 6.46042 13.8979 6.25625 13.6938L0.30625 7.74375C0.102083 7.53958 0 7.29167 0 7C0 6.70833 0.102083 6.46042 0.30625 6.25625Z"/>
        </svg>
    );
};

export default BackIcon;
