import React, {useState, useEffect} from "react";
import {PageSidebar as PfPageSidebar, Nav, NavList, NavItem, NavSelectClickHandler} from "@patternfly/react-core";
import {useHistory} from "react-router-dom";

import MemberIcon from "../../icons/MemberIcon";
import ImportIcon from "../../icons/ImportIcon";
import AdminIcon from "../../icons/AdminIcon";
import {Routes} from "../../utils/constants";
import {isAdmin} from "../../services/loginService";

export interface PageSidebarProps {
    opened: boolean;
}

const PageSidebar: React.FC<PageSidebarProps> = ({opened}) => {

    const [activeNavItem, setActiveNavItem] = useState(-1);
    const history = useHistory();

    const items: string[] = [
        Routes.MemberSchemes,
        Routes.Import,
        Routes.AdminManager
    ];

    useEffect(() => {
        const {pathname} = history.location;

        const index = items.findIndex(item => pathname.startsWith(item));
        setActiveNavItem(index);
    });

    const onNavSelect = (result: { to: string }) => {
        setActiveNavItem(items.indexOf(result.to));
    };

    const onNavItemClick: NavSelectClickHandler = (e, itemId, groupId, to) => {
        e.preventDefault();
        history.push(to);
    };


    return (
        <PfPageSidebar
            isNavOpen={opened}
            nav={(
                <>
                    <Nav onSelect={onNavSelect} aria-label="Nav" theme="dark">
                        <NavList variant="simple">
                            <NavItem
                                itemId={0}
                                to={Routes.MemberSchemes}
                                onClick={onNavItemClick}
                                isActive={activeNavItem === 0}
                                preventDefault
                            >
                                <MemberIcon className="tp--sidebar__nav-item__icon"/>
                                <span className="tp--sidebar__nav-item__label">Member manager</span>
                            </NavItem>
                            <NavItem
                                itemId={1}
                                to={Routes.Import}
                                onClick={onNavItemClick}
                                isActive={activeNavItem === 1}
                                preventDefault
                            >
                                <ImportIcon className="tp--sidebar__nav-item__icon"/>
                                <span className="tp--sidebar__nav-item__label">Import</span>
                            </NavItem>
                            {
                                isAdmin() &&
                                <NavItem
                                    itemId={2}
                                    to={Routes.AdminManager}
                                    onClick={onNavItemClick}
                                    isActive={activeNavItem === 2}
                                    preventDefault
                                >
                                    <AdminIcon className="tp--sidebar__nav-item__icon tp--icon__admin"/>
                                    <span className="tp--sidebar__nav-item__label">Admin manager</span>
                                </NavItem>
                            }
                        </NavList>
                    </Nav>
                    <div className="tp--sidebar__copyright">
                        <p>Copyright © {new Date().getFullYear()} </p>
                        <p className="tp--sidebar__copyright__name">mypensionID.</p>
                        <p>All rights reserved.</p>
                    </div>
                </>
            )}
            theme="dark"
        />
    );
};

export default PageSidebar;
