import React from "react";

const MemberIcon: React.FC<{ className?: string }> = ({className}) => {
    return (
        <svg className={className} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0ZM16 2H2V16H16V2ZM9 9C7.35 9 6 7.65 6 6C6 4.35 7.35 3 9 3C10.65 3 12 4.35 12 6C12 7.65 10.65 9 9 9ZM8 6C8 5.45 8.45 5 9 5C9.55 5 10 5.45 10 6C10 6.55 9.55 7 9 7C8.45 7 8 6.55 8 6ZM3 15V13.47C3 10.97 6.97 9.89 9 9.89C11.03 9.89 15 10.97 15 13.47V15H3ZM9 11.88C10.31 11.88 12 12.44 12.69 13H5.31C5.99 12.44 7.69 11.88 9 11.88Z"
            />
        </svg>
    );
};

export default MemberIcon;
