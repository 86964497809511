import React from "react";
import {
    Title,
    Button,
    EmptyState,
    EmptyStateIcon,
    EmptyStateBody,
    EmptyStatePrimary
} from "@patternfly/react-core";
import {SearchIcon} from "@patternfly/react-icons";

export interface NoMatchEmptyStateProps{
    onClearAllFilters?: () => void;
}

const NoMatchEmptyState: React.FC<NoMatchEmptyStateProps> = ({onClearAllFilters}) => {
    const clearFilters = (e: React.MouseEvent) => {
        e.preventDefault();
        onClearAllFilters && onClearAllFilters();
    };

    return (
        <EmptyState>
            <EmptyStateIcon icon={SearchIcon}/>
            <Title size="lg">
                No results found
            </Title>
            <EmptyStateBody>
                No results match the filter criteria. Remove all filters or clear all filters to show results.
            </EmptyStateBody>
            <EmptyStatePrimary>
                {onClearAllFilters && <Button variant="link" onClick={clearFilters}>Clear all filters</Button>}
            </EmptyStatePrimary>
        </EmptyState>
    );
};

export default NoMatchEmptyState;
