import {SortBy} from "../../utils/types";

export const setPageAction = (page: number) => ({
    type: "SET_PAGE" as "SET_PAGE",
    page
});

export const setPageSizeAction = (pageSize: number) => ({
    type: "SET_PAGE_SIZE" as "SET_PAGE_SIZE",
    pageSize
});

export const setSortByAction = (sortBy: SortBy) => ({
    type: "SET_SORT_BY" as "SET_SORT_BY",
    sortBy
});


export type PaginationActions = ReturnType<typeof setPageAction> | ReturnType<typeof setPageSizeAction> | ReturnType<typeof setSortByAction>
