import React, {useState} from "react";
import {
    Button,
    ButtonVariant,
    Modal,
    Form,
    TextInput,
    FormGroup,
    Select,
    SelectVariant,
    SelectOption,
    SelectOptionObject,
    Switch
} from "@patternfly/react-core";
import {EditUserData, UserRoleName} from "../../../utils/types";


type ValidFields = {
    name: boolean;
    email: boolean;
}


const roleOptions: Record<string, any> = {
    "Operator": <SelectOption key="Operator" value="Operator"/>,
    "Admin": <SelectOption key="Admin" value="Admin"/>
};

export interface UserModalProps extends Partial<EditUserData> {
    primaryButtonText: string;
    saveUser: (user: EditUserData) => void;
    closeModal: () => void;
}

const UserModal: React.FC<UserModalProps> = ({
    primaryButtonText,
    name = "",
    email = "",
    role = "Operator",
    active = true,
    saveUser,
    closeModal
}) => {
    const [_name, setName] = useState(name);
    const [_email, setEmail] = useState(email);
    const [_role, setRole] = useState<UserRoleName>(role);
    const [_active, setActive] = useState(active);

    // mark fields as touched if we are editing as value already exists
    const [touchedFields, setTouchedFields] = useState<ValidFields>({
        name: !!_name,
        email: !!email
    });
    // mark fields valid if we are editing so user can save them wihout touching the fields
    const [validFields, setValidFields] = useState<ValidFields>({
        name: !!_name,
        email: !!_email
    });

    const [roleExpanded, setRoleExpanded] = useState(false);


    const updateFieldValidity = (fieldName: keyof ValidFields, isValid: boolean) => setValidFields({
        ...validFields,
        [fieldName]: isValid
    });

    const updateTouchedFields = (fieldName: "name" | "email") => setTouchedFields({
        ...touchedFields,
        [fieldName]: true
    });

    const updateName = (name: string) => setName(name);
    const validateName = () => {
        updateTouchedFields("name");
        updateFieldValidity("name", _name.trim().length > 0);
    };

    const updateEmail = (email: string) => setEmail(email);
    const validateEmail = () => {
        updateTouchedFields("email");
        updateFieldValidity("email", _email.includes("@") && _email.length >= 3);
    };

    const onRoleChange = (e, value: string | SelectOptionObject) => {
        const selection: any = value.toString();
        setRole(selection);
        setRoleExpanded(false);
    };

    const onActiveChange = (value: boolean) => setActive(value);


    const onSaveUser = () => {
        saveUser({
            name: _name,
            email: _email,
            role: _role,
            active: _active
        });
    };

    const hasInvalidField = () => Object.values(touchedFields).some(touched => !touched) ||
        Object.values(validFields).some(isValid => !isValid);

    return (

        <Modal
            title="Add new user"
            className="tp--add-user"
            isOpen
            isLarge
            onClose={closeModal}
            isFooterLeftAligned
            actions={[
                <Button key="add" isDisabled={hasInvalidField()} variant={ButtonVariant.primary} onClick={onSaveUser}>
                    {primaryButtonText}
                </Button>,
                <Button key="cancel" variant={ButtonVariant.link} onClick={closeModal}>
                    Cancel
                </Button>
            ]}
        >
            <Form isHorizontal>
                <FormGroup
                    label="Name"
                    isRequired
                    fieldId="name"
                    helperText="Please provide user full name"
                >
                    <TextInput
                        isRequired
                        type="text"
                        id="name"
                        name="name"
                        value={_name}
                        aria-describedby="name-helper"
                        isValid={!touchedFields.name || validFields.name}
                        onChange={updateName}
                        onBlur={validateName}
                    />
                </FormGroup>
                <FormGroup
                    label="Email"
                    isRequired
                    fieldId="email"
                    helperText="Please provide user email address"
                >
                    <TextInput
                        isRequired
                        type="text"
                        id="email"
                        name="email"
                        value={_email}
                        aria-describedby="email-helper"
                        isValid={!touchedFields.email || validFields.email}
                        onChange={updateEmail}
                        onBlur={validateEmail}
                    />
                </FormGroup>
                <FormGroup
                    label="Role"
                    fieldId="role"
                    helperText="Please pick user role"
                >
                    <Select
                        id="role"
                        name="role"
                        variant={SelectVariant.single}
                        aria-ariaLabelledBy="role-helper"
                        onSelect={onRoleChange}
                        onToggle={setRoleExpanded}
                        isExpanded={roleExpanded}
                        selections={_role}
                    >
                        {Object.values(roleOptions)}
                    </Select>
                </FormGroup>
                <FormGroup
                    label="Activate"
                    fieldId="active"
                >
                    <Switch
                        id="active"
                        name="active"
                        isChecked={_active}
                        aria-label="Choose if user should be enabled"
                        onChange={onActiveChange}
                    />
                </FormGroup>
            </Form>
        </Modal>
    );
};

export default UserModal;
