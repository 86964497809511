import {User, EditUserData} from "../../utils/types";
import {PaginationActions, setPageAction, setPageSizeAction, setSortByAction} from "./pagination";

export {setPageAction};
export {setPageSizeAction};
export {setSortByAction};

export const setUsersAction = (users: User[]) => ({
    type: "SET_USERS" as "SET_USERS",
    users
});

export const setUserNameFilterAction = (nameFilter: string) => ({
    type: "SET_NAME_FILTER" as "SET_NAME_FILTER",
    nameFilter
});

export const editUserAction = (editedUser: EditUserData & {id: number}) => ({
    type: "EDIT_USER" as "EDIT_USER",
    editedUser
});

export const cancelEditUserAction = () => ({
    type: "CANCEL_EDIT_USER" as "CANCEL_EDIT_USER"
});

export type UserManagementActions = PaginationActions | ReturnType<typeof setUsersAction> | ReturnType<typeof setUserNameFilterAction> |
ReturnType<typeof editUserAction> | ReturnType<typeof cancelEditUserAction>;
