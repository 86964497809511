import {History} from "history";

import {callSecuredApiEndpoint, ApiJsonResponse} from "./apiCalls";
import {API_BASE_URL} from "../utils/constants";


export type ImportResponseJson = ApiJsonResponse & {
    id: number;
    status: "PENDING" | "FAILURE" | "SUCCESS"; // "PENDING"
    created: string;
    lastUpdated: string;
    project: string | null;
    validationErrors: string[];
};

export type ImportSettings = {
    supportsBankDetails: boolean;
    supportsSpouseDetails: boolean;
}
true;
/**
 * Definition of fetch call for members endpoint
 */
const sendImportFile = (file: File, settings: ImportSettings) => (authorization: string) => {
    const formData = new window.FormData();
    const settingsFile = new window.Blob([JSON.stringify(settings)], {type: "application/json"});

    formData.append("file", file);
    formData.append("settings", settingsFile);

    return fetch(
        `${API_BASE_URL}members/imports`,
        {
            body: formData,
            headers: new window.Headers({
                Authorization: authorization
            }),
            method: "POST"
        }
    ).then(response => response.json()) as Promise<ImportResponseJson>;
};

export const uploadFile = (file: File, settings: ImportSettings, history: History) => callSecuredApiEndpoint(sendImportFile(file, settings), history);


const requestImportStatus = (id: string) => (authorization: string) =>
    fetch(
        `${API_BASE_URL}members/imports/${id}`,
        {
            headers: new window.Headers({
                Authorization: authorization
            }),
            method: "GET"
        }
    ).then(response => response.json()) as Promise<ImportResponseJson>;

export const getImportStatus = (id: string, history: History) => callSecuredApiEndpoint(requestImportStatus(id), history);
