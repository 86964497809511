import React, {useState, useEffect} from "react";
import {
    Table,
    TableHeader,
    TableBody,
    sortable,
    ISortBy,
    ICell,
    IRow,
    TableGridBreakpoint,
    IAction,
    TableVariant,
    IRowData
} from "@patternfly/react-table";

import {SortByDirection, User, ColumnDef} from "../../../utils/types";
import DeclineIcon from "../../../icons/DeclinedIcon";
import CheckIcon from "../../../icons/CheckIcon";


type RowCell = {
    title: React.ReactNode;
    value: string | boolean | number;
}

type Row = IRow & {
    cells: RowCell[];
    uid: number;
    name: string;
    active: boolean;
};

export interface SortableUsersTableProps {
    columnsDefs: ColumnDef[];
    data: User[];
    sortBy: ISortBy;
    onSorted: (index: number, direction: SortByDirection) => void;
    onEdit: (id: number) => void;
    onSuspend: (id: number, name: string) => void;
    onUnsuspend: (id: number, name: string) => void;
    onDelete: (id: number, name: string) => void;
}


const SortableUsersTable: React.FC<SortableUsersTableProps> = ({
    data = [],
    columnsDefs,
    sortBy,
    onSorted,
    onEdit,
    onSuspend,
    onUnsuspend,
    onDelete
}) => {

    const [rows, setRows] = useState<Row[]>([]);
    const rowKeys = columnsDefs.map(def => def.key);

    const blockAction = {
        title: "Block user",
        onClick: (e, rowIndex, rowData) => onSuspend(rowData.uid, rowData.name)
    };

    const unBlockAction = {
        title: "Unblock user",
        onClick: (e, rowIndex, rowData) => onUnsuspend(rowData.uid, rowData.name)
    };

    const deleteAction = {
        title: "Delete user",
        onClick: (e, rowIndex, rowData) => onDelete(rowData.uid, rowData.name)
    };

    const editAction = {
        title: "Edit user",
        onClick: (e, rowIndex, rowData) => onEdit(rowData.uid)
    };

    useEffect(() => {
        setRows(data.map(user => {
            const row: Row = {
                cells: [],
                uid: user.id,
                name: user.name,
                active: user.active
            };

            rowKeys.forEach(key => {
                const value = user[key];
                const rowCell: RowCell = {
                    title: value,
                    value
                };

                if (typeof value === "boolean") {
                    rowCell.title = value ? <CheckIcon fill="#92D400" title="Active"/> : <DeclineIcon fill="#E14C4C" title="Inactive"/>;
                }
                row.cells.push(rowCell);

            });
            return row;
        }));
    }, [data]);

    const columns: ICell[] = columnsDefs.map(def => ({
        title: def.title,
        transforms: def.sortable ? [sortable] : []
    }));


    const onSort = (_event: any, index: number, direction: SortByDirection) => {
        onSorted(index, direction);
    };


    const actionResolver = (rowData: IRowData) => {
        const row = rowData as Row;

        const actions: IAction[] = [
            editAction,
            (row.active ? blockAction : unBlockAction),
            deleteAction
        ];

        return actions;
    };


    return (
        <Table
            aria-label="Users Table"
            variant={TableVariant.compact}
            sortBy={sortBy}
            onSort={onSort}
            cells={columns}
            rows={rows}
            gridBreakPoint={TableGridBreakpoint.gridLg}
            actionResolver={actionResolver}
        >
            <TableHeader className="tp--table__header"/>
            <TableBody/>
        </Table>
    );
};

export default SortableUsersTable;
