import React from "react";

const DeclineIcon: React.FC<{ className?: string; fill?: string; title?: string }> = ({className, fill = "#E14C4C", title}) => {
    return (
        <span title={title}>
            <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M9 0C4.02823 0 0 4.02823 0 9C0 13.9718 4.02823 18 9 18C13.9718 18 18 13.9718 18 9C18 4.02823 13.9718 0 9 0ZM13.4129 11.3625C13.5835 11.5331 13.5835 11.8089 13.4129 11.9794L11.9758 13.4129C11.8052 13.5835 11.5294 13.5835 11.3589 13.4129L9 11.0323L6.6375 13.4129C6.46694 13.5835 6.19113 13.5835 6.02056 13.4129L4.5871 11.9758C4.41653 11.8052 4.41653 11.5294 4.5871 11.3589L6.96774 9L4.5871 6.6375C4.41653 6.46694 4.41653 6.19113 4.5871 6.02056L6.02419 4.58347C6.19476 4.4129 6.47056 4.4129 6.64113 4.58347L9 6.96774L11.3625 4.5871C11.5331 4.41653 11.8089 4.41653 11.9794 4.5871L13.4165 6.02419C13.5871 6.19476 13.5871 6.47056 13.4165 6.64113L11.0323 9L13.4129 11.3625Z"/>
            </svg>
        </span>
    );
};


export default DeclineIcon;
