import {MemberSchemesState} from "../reducers/memberSchemesReducer";
import {SchemeProfileRaw, SchemeProfileAddress, SchemeProfileSpouseDetail, SchemeProfileBankDetail, SchemeProfile, SortBy, SortByDirection, Project, ProjectClient, MemberSchemeGender, MemberSchemeMaritalStatus} from "./types";

export function buildQueryFromState(state: MemberSchemesState) {
    const paginationQuery = `page=${state.page - 1}&size=${state.pageSize}&sort=${state.sortBy.key},${state.sortBy.direction}`;

    if (state.filtersQuery) {
        return `${state.filtersQuery}&${paginationQuery}`;
    } else {
        return paginationQuery;
    }
}

export function padNumber(number: number) {
    if (number < 10) {
        return "0" + number;
    }
    return number.toString();
}

export function convertDateToApiFormat(date: Date) {
    return `${padNumber(date.getFullYear())}-${padNumber(date.getMonth() + 1)}-${padNumber(date.getDate())}`;
}

export function formatDateForExportName(date: Date) {
    return `${padNumber(date.getFullYear())}${padNumber(date.getMonth() + 1)}${padNumber(date.getDate())}${padNumber(date.getHours())}${padNumber(date.getMinutes())}`;
}

export function normalizeSexValue(value: string): MemberSchemeGender {
    // handle null value
    if (typeof value !== "string") {
        value = "";
    }
    switch (value.toLowerCase().trim()) {

        case "f":
            return "Female";
        case "m":
            return "Male";
        case "o":
            return "Other";
        case "":
            return "";
        default:
            return "Unknown";
    }
}

export function normalizeMaritalStatusValue(value: string): MemberSchemeMaritalStatus {
    if (typeof value !== "string") {
        value = "";
    }

    switch (value.toLowerCase().trim()) {

        case "married":
            return "Married";
        case "cohabiting":
            return "Cohabiting";
        case "single":
            return "Single";
        case "widow_or_widower":
            return "Widow/Widower";
        case "prefer_not_to_say":
            return "Prefer not to say";
        default:
            return "";
    }
}

export function normalizeProfileData(rawProfile: SchemeProfileRaw) {

    const address: Partial<SchemeProfileAddress> = rawProfile.address || {};
    const spouseDetail: Partial<SchemeProfileSpouseDetail> = rawProfile.spouseDetail || {};
    const bankDetail: Partial<SchemeProfileBankDetail> = rawProfile.bankDetail || {};

    const normalizedProfile: SchemeProfile = {
        title: rawProfile.title || "",
        forename: rawProfile.forename || "",
        middleName: rawProfile.middleName || "",
        surname: rawProfile.surname || "",
        sex: normalizeSexValue(rawProfile.sex || ""),
        dateOfBirth: rawProfile.dateOfBirth || "",
        address: {
            line1: address.line1 || "",
            line2: address.line2 || "",
            townCity: address.townCity || "",
            country: address.country || "",
            postcode: address.postcode || "",
            countyOrProvince: address.countyOrProvince || ""
        },
        spouseDetail: {
            forename: spouseDetail.forename || "",
            middleName: spouseDetail.middleName || "",
            surname: spouseDetail.surname || "",
            sex: normalizeSexValue(spouseDetail.sex || ""),
            dateOfBirth: spouseDetail.dateOfBirth || ""
        },
        bankDetail: {
            accountNumber: bankDetail.accountNumber || "",
            sortCode: bankDetail.sortCode || "",
            accountName: bankDetail.accountName || ""
        },
        maritalStatus: normalizeMaritalStatusValue(rawProfile.maritalStatus || ""),
        email: rawProfile.email || "",
        telephone: rawProfile.telephone || "",
        lastUpdated: rawProfile.lastUpdated || ""
    };

    return normalizedProfile;
}

export function normalizeProjectData(rawProject: Partial<Project>) {
    const client: Partial<ProjectClient> = rawProject.client || {};

    const project: Project = {
        number: rawProject.number || "",
        client: {
            id: client.id || -1,
            number: client.number || "",
            name: client.name || "",
            contactName: client.contactName || "",
            contactEmail: client.contactEmail || ""
        }
    };

    return project;
}


export function sortItems<T>(items: T[], sortBy: SortBy) {
    const key = sortBy.key;
    // create new copy of array!
    const sortedItems = [...items];

    return sortedItems.sort((a: T, b: T) => {
        const aValue = a[key];
        const bValue = b[key];

        let result = 0;

        if (typeof aValue === "string") {
            result = aValue.localeCompare(bValue);

        } else {
            if (aValue < bValue) {
                result = -1;

            } else if (aValue > bValue) {
                result = 1;
            }
        }

        return sortBy.direction === SortByDirection.asc ? result : result * -1;
    });
}
